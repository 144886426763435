import React from "react";
const MasterInvoice = React.lazy(() =>
  import("../views/pages/invoice/masterInvoice/MasterInvoice")
);
const NewMasterInvoice = React.lazy(() =>
  import("../views/pages/invoice/masterInvoice/New")
);
const EditMasterInvoice = React.lazy(() =>
  import("../views/pages/invoice/masterInvoice/Edit")
);

const SingleInvoice = React.lazy(() =>
  import("../views/pages/invoice/singleInvoice/SingleInvoice")
);
const NewSingleInvoice = React.lazy(() =>
  import("../views/pages/invoice/singleInvoice/New")
);
const EditSingleInvoice = React.lazy(() =>
  import("../views/pages/invoice/singleInvoice/Edit")
);

const AdminInvoice = React.lazy(() =>
  import("../views/pages/invoice/admin/admin-invoice")
);
const MemberInvoice = React.lazy(() =>
  import("../views/pages/invoice/member/member-invoice")
);
const MerchantInvoice = React.lazy(() =>
  import("../views/pages/invoice/merchant/merchant-invoice")
);
const TransactionHistoryInvoiceSummary = React.lazy(() =>
  import("../views/pages/invoice/transaction-history/Summary")
);
const TransactionHistoryInvoiceHistory = React.lazy(() =>
  import("../views/pages/invoice/transaction-history/History")
);
const invoiceSendConfig = React.lazy(() =>
  import("../views/pages/invoice/invoiceSendConfig")
);

const invoiceRoutes = [
  {
    path: "/invoice/master-invoice",
    name: "Master Invoice",
    element: MasterInvoice,
  },
  {
    path: "/invoice/master-invoice/new",
    name: "Tambah Master Invoice",
    element: NewMasterInvoice,
  },
  {
    path: "/invoice/master-invoice/edit/:id",
    name: "Edit Master Invoice",
    element: EditMasterInvoice,
  },
  {
    path: "/invoice/invoice-tunggal",
    name: "Single Invoice",
    element: SingleInvoice,
  },
  {
    path: "/invoice/invoice-tunggal/new",
    name: "Tambah Single Invoice",
    element: NewSingleInvoice,
  },
  {
    path: "/invoice/invoice-tunggal/edit/:id",
    name: "Edit Single Invoice",
    element: EditSingleInvoice,
  },
  {
    path: "/invoice/invoice-admin",
    name: "Admin Invoice",
    element: AdminInvoice,
  },
  {
    path: "/invoice/invoice-member",
    name: "Merchant Invoice",
    element: MemberInvoice,
  },
  {
    path: "/invoice/invoice-merchant",
    name: "Merchant Invoice",
    element: MerchantInvoice,
  },
  {
    path: "/invoice/data-transaksi-invoice/:id",
    name: "Data Transaksi Invoice",
    element: TransactionHistoryInvoiceHistory,
  },
  {
    path: "/invoice/data-transaksi-invoice",
    name: "Data Transaksi Invoice",
    element: TransactionHistoryInvoiceSummary,
  },
  {
    path: "/invoice/invoice-sending-configuration",
    element: invoiceSendConfig,
  },
];

export default invoiceRoutes;
