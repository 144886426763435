import React from "react";
const credentialSetting = React.lazy(() =>
  import("../views/pages/integrationManagement/credentialSettings")
);
const callbackHistory = React.lazy(() =>
  import("../views/pages/integrationManagement/history")
);
const detailCallbackHistory = React.lazy(() =>
  import("../views/pages/integrationManagement/detailHistory")
);

const integrationManagementRoutes = [
  {
    path: "/integration-management/credential-settings",
    element: credentialSetting,
  },
  {
    path: "/integration-management/history",
    element: callbackHistory,
  },
  {
    path: "/integration-management/history/detail/:id",
    element: detailCallbackHistory,
  },
];

export default integrationManagementRoutes;
