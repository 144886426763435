import React from "react";
const getCheckout = React.lazy(() =>
  import("../views/pages/balanceAndPayment/configCheckout/getCheckout")
);
const editCheckout = React.lazy(() =>
  import("../views/pages/balanceAndPayment/configCheckout/editCheckout")
);
const firstConfigCheckout = React.lazy(() =>
  import("../views/pages/balanceAndPayment/configCheckout/firstConfigCheckout")
);
const firstSetConfigCheckout = React.lazy(() =>
  import(
    "../views/pages/balanceAndPayment/configCheckout/firstSetConfigCheckout"
  )
);
const accountBankCompany = React.lazy(() =>
  import(
    "../views/pages/balanceAndPayment/companyBankAccount/companyBankAccount"
  )
);
const configTransferMember = React.lazy(() =>
  import("../views/pages/balanceAndPayment/configCharge/transferMember")
);
const configTransferBank = React.lazy(() =>
  import("../views/pages/balanceAndPayment/configCharge/transferBank")
);
const configTransactionQrcode = React.lazy(() =>
  import("../views/pages/balanceAndPayment/configCharge/qrCodeTransaction")
);
const configTopupVa = React.lazy(() =>
  import("../views/pages/balanceAndPayment/configCharge/topupVA")
);
const configTopupMerchant = React.lazy(() =>
  import("../views/pages/balanceAndPayment/configCharge/topupMerchant")
);
const configTopupAdmin = React.lazy(() =>
  import("../views/pages/balanceAndPayment/configCharge/topupAdmin")
);
const configWithdrawAdmin = React.lazy(() =>
  import("../views/pages/balanceAndPayment/configCharge/withdrawAdmin")
);
const configWithdrawMerchant = React.lazy(() =>
  import("../views/pages/balanceAndPayment/configCharge/withdrawMerchant")
);
const financeMenu = React.lazy(() =>
  import("../views/pages/balanceAndPayment/financeMenu/financeMenu")
);
const financeMenuDisbursement = React.lazy(() =>
  import("../views/pages/balanceAndPayment/financeMenu/disbursement")
);
const memberBalanceData = React.lazy(() =>
  import("../views/pages/balanceAndPayment/balanceData/member")
);
const merchantBalanceData = React.lazy(() =>
  import("../views/pages/balanceAndPayment/balanceData/merchant")
);
const balanceConfig = React.lazy(() =>
  import("../views/pages/balanceAndPayment/balanceConfiguration/balanceConfig")
);
const memberMutationData = React.lazy(() =>
  import("../views/pages/balanceAndPayment/mutationData/member")
);
const merchantMutationData = React.lazy(() =>
  import("../views/pages/balanceAndPayment/mutationData/merchant")
);
const balanceTransactionData = React.lazy(() =>
  import("../views/pages/balanceAndPayment/transactionData/summaryTransaction")
);
const creditMemberBalance = React.lazy(() =>
  import("../views/pages/balanceAndPayment/balanceData/creditBalance")
);
const balanceTransactionDataTopupVA = React.lazy(() =>
  import("../views/pages/balanceAndPayment/transactionData/topupVA")
);
const balanceTransactionDataTopupMerchant = React.lazy(() =>
  import("../views/pages/balanceAndPayment/transactionData/topupMerchant")
);
const balanceTransactionDataTopupAdmin = React.lazy(() =>
  import("../views/pages/balanceAndPayment/transactionData/topupAdmin")
);
const balanceTransactionDataWithdrawMerchant = React.lazy(() =>
  import("../views/pages/balanceAndPayment/transactionData/withdrawMerchant")
);
const balanceTransactionDataDisbursementAdmin = React.lazy(() =>
  import("../views/pages/balanceAndPayment/transactionData/disbursementAdmin")
);
const balanceTransactionDataTransferMember = React.lazy(() =>
  import("../views/pages/balanceAndPayment/transactionData/transferMember")
);
const balanceTransactionDataTransferBank = React.lazy(() =>
  import("../views/pages/balanceAndPayment/transactionData/transferBank")
);
const balanceTransactionDataQrcode = React.lazy(() =>
  import("../views/pages/balanceAndPayment/transactionData/qrCode")
);
const balanceTransferCompany = React.lazy(() =>
  import(
    "../views/pages/balanceAndPayment/balanceData/transferCompany/transferBalance"
  )
);
const paymentMethodBalanceTransfer = React.lazy(() =>
  import(
    "../views/pages/balanceAndPayment/balanceData/transferCompany/paymentMethod"
  )
);
const lastPaymentBalanceTransfer = React.lazy(() =>
  import(
    "../views/pages/balanceAndPayment/balanceData/transferCompany/lastPayment"
  )
);
const financialData = React.lazy(() =>
  import("../views/pages/balanceAndPayment/financialData/financialData")
);
const financialDataTransactionProfit = React.lazy(() =>
  import("../views/pages/balanceAndPayment/transactionData/transactionProfit")
);
const financialDataTransactionFee = React.lazy(() =>
  import("../views/pages/balanceAndPayment/transactionData/transactionFee")
);
const superBalanceFinanceManagement = React.lazy(() =>
  import("../views/pages/superadmin/financeManagement/balance/balanceFinance")
);
const withdrawMerchant = React.lazy(() =>
  import("../views/pages/balanceAndPayment/withdrawMerchant/withdrawMerchant")
);
const balanceTransactionDataTopupVAMerchant = React.lazy(() =>
  import("../views/pages/balanceAndPayment/transactionData/topupVAMerchant")
);
const configChargeQris = React.lazy(() =>
  import("../views/pages/balanceAndPayment/configCharge/qris")
);
const trxQris = React.lazy(() =>
  import("../views/pages/balanceAndPayment/transactionData/qris")
);
const manualDisbursement = React.lazy(() =>
  import("../views/pages/balanceAndPayment/manualDisbursement")
);
const chooseTrxVa = React.lazy(() =>
  import("../views/pages/balanceAndPayment/dataTrx/trxVa")
);
const chooseWdMerchant = React.lazy(() =>
  import("../views/pages/balanceAndPayment/dataTrx/wdMerchant")
);
const chooseBalanceData = React.lazy(() =>
  import("../views/pages/balanceAndPayment/dataTrx/chooseBalanceData")
);
const chooseMutationData = React.lazy(() =>
  import("../views/pages/balanceAndPayment/dataTrx/chooseMutation")
);
const logVA = React.lazy(() =>
  import("../views/pages/balanceAndPayment/mutationData/logVA")
);

const balanceRoutes = [
  {
    path: "/balance-payment/config-checkout",
    element: getCheckout,
  },
  {
    path: "/balance-payment/config-edit-checkout",
    element: editCheckout,
  },
  {
    path: "/balance-payment/first-config-checkout",
    element: firstConfigCheckout,
  },
  {
    path: "/balance-payment/first-config-edit-checkout",
    element: firstSetConfigCheckout,
  },
  {
    path: "/balance-payment/account-bank",
    element: accountBankCompany,
  },
  {
    path: "/balance-payment/config/transfer-member",
    element: configTransferMember,
  },
  {
    path: "/balance-payment/config/transfer-bank",
    element: configTransferBank,
  },
  {
    path: "/balance-payment/config/qrcode",
    element: configTransactionQrcode,
  },
  {
    path: "/balance-payment/config/topup-va",
    element: configTopupVa,
  },
  {
    path: "/balance-payment/config/topup-merchant",
    element: configTopupMerchant,
  },
  {
    path: "/balance-payment/config/topup-admin",
    element: configTopupAdmin,
  },
  {
    path: "/balance-payment/config/withdraw-admin",
    element: configWithdrawAdmin,
  },
  {
    path: "/balance-payment/config/withdraw-merchant",
    element: configWithdrawMerchant,
  },
  {
    path: "/balance-payment/finance-menu",
    element: financeMenu,
  },
  {
    path: "/balance-payment/finance-menu/disbursement",
    element: financeMenuDisbursement,
  },
  {
    path: "/balance-payment/balance/member-balance",
    element: memberBalanceData,
  },
  {
    path: "/balance-payment/balance/merchant-balance",
    element: merchantBalanceData,
  },
  {
    path: "/balance-payment/config-balance",
    element: balanceConfig,
  },
  {
    path: "/balance-payment/mutation/member-mutation",
    element: memberMutationData,
  },
  {
    path: "/balance-payment/mutation/merchant-mutation",
    element: merchantMutationData,
  },
  {
    path: "/balance-payment/transaction",
    element: balanceTransactionData,
  },
  {
    path: "/balance-payment/balance/member-balance/credit",
    element: creditMemberBalance,
  },
  {
    path: "/balance-payment/transaction/topup-va-member",
    element: balanceTransactionDataTopupVA,
  },
  {
    path: "/balance-payment/transaction/topup-merchant",
    element: balanceTransactionDataTopupMerchant,
  },
  {
    path: "/balance-payment/transaction/topup-admin",
    element: balanceTransactionDataTopupAdmin,
  },
  {
    path: "/balance-payment/transaction/withdraw-merchant",
    element: balanceTransactionDataWithdrawMerchant,
  },
  {
    path: "/balance-payment/transaction/disbursement-admin",
    element: balanceTransactionDataDisbursementAdmin,
  },
  {
    path: "/balance-payment/transaction/transfer-member",
    element: balanceTransactionDataTransferMember,
  },
  {
    path: "/balance-payment/transaction/transfer-bank",
    element: balanceTransactionDataTransferBank,
  },
  {
    path: "/balance-payment/transaction/barcode",
    element: balanceTransactionDataQrcode,
  },
  {
    path: "/balance-payment/balance/balance-transfer-company",
    element: balanceTransferCompany,
  },
  {
    path: "/balance-payment/balance/balance-transfer-company/payment-method",
    element: paymentMethodBalanceTransfer,
  },
  {
    path: "/balance-payment/balance/balance-transfer-company/last-payment",
    element: lastPaymentBalanceTransfer,
  },
  {
    path: "/balance-payment/financial-data",
    element: financialData,
  },
  {
    path: "/balance-payment/transaction/transaction-profit",
    element: financialDataTransactionProfit,
  },
  {
    path: "/balance-payment/transaction/transaction-fee",
    element: financialDataTransactionFee,
  },
  {
    path: "/master-finance-management/balance",
    element: superBalanceFinanceManagement,
  },
  {
    path: "/balance-payment/withdraw-merchant",
    element: withdrawMerchant,
  },
  {
    path: "/balance-payment/transaction/topup-va-merchant",
    element: balanceTransactionDataTopupVAMerchant,
  },
  {
    path: "/balance-payment/config/qris",
    element: configChargeQris,
  },
  {
    path: "/balance-payment/transaction/qris",
    element: trxQris,
  },
  {
    path: "/balance-payment/manual-disbursement",
    element: manualDisbursement,
  },
  {
    path: "/transaction/topup-data",
    element: chooseTrxVa,
  },
  {
    path: "/transaction/merchant-withdrawal-transaction-data",
    element: chooseWdMerchant,
  },
  {
    path: "/transaction/balance",
    element: chooseBalanceData,
  },
  {
    path: "/transaction/mutation",
    element: chooseMutationData,
  },
  {
    path: "/log-va",
    element: logVA,
  },
];

export default balanceRoutes;
