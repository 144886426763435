import React from "react";
const donation = React.lazy(() =>
  import("../views/pages/donation/donation/Donation")
);
const donationEdit = React.lazy(() =>
  import("../views/pages/donation/donation/Edit")
);
const donationAdd = React.lazy(() =>
  import("../views/pages/donation/donation/New")
);
const donationBenefactor = React.lazy(() =>
  import("../views/pages/donation/donation/Benefactors")
);
const donationProgress = React.lazy(() =>
  import("../views/pages/donation/donation/Progress")
);
const donationProgressNew = React.lazy(() =>
  import("../views/pages/donation/donation/ProgressNew")
);
const donationProgressEdit = React.lazy(() =>
  import("../views/pages/donation/donation/ProgressEdit")
);
const donationDisbursement = React.lazy(() =>
  import("../views/pages/donation/donation/Disbursement")
);
const donationDisbursementNew = React.lazy(() =>
  import("../views/pages/donation/donation/DisbursementNew")
);
const zakat = React.lazy(() => import("../views/pages/donation/zakat/Zakat"));
const zakatEdit = React.lazy(() =>
  import("../views/pages/donation/zakat/Edit")
);
const zakatAdd = React.lazy(() => import("../views/pages/donation/zakat/New"));
const zakatBenefactor = React.lazy(() =>
  import("../views/pages/donation/zakat/Benefactors")
);
const zakatProgress = React.lazy(() =>
  import("../views/pages/donation/zakat/Progress")
);
const zakatProgressNew = React.lazy(() =>
  import("../views/pages/donation/zakat/ProgressNew")
);
const zakatProgressEdit = React.lazy(() =>
  import("../views/pages/donation/zakat/ProgressEdit")
);
const zakatDisbursement = React.lazy(() =>
  import("../views/pages/donation/zakat/Disbursement")
);
const zakatDisbursementNew = React.lazy(() =>
  import("../views/pages/donation/zakat/DisbursementNew")
);
const propertiesMenuDonation = React.lazy(() =>
  import("../views/pages/donation/properties/properties")
);

const donationRoutes = [
  {
    path: "/donation/donation",
    name: "Data Donasi",
    element: donation,
  },
  {
    path: "/donation/donation/benefactor/:id",
    element: donationBenefactor,
  },
  {
    path: "/donation/donation/progress/:id",
    element: donationProgress,
  },
  {
    path: "/donation/donation/progress/:id/new",
    element: donationProgressNew,
  },
  {
    path: "/donation/donation/progress/:id/:progressId",
    element: donationProgressEdit,
  },
  {
    path: "/donation/donation/disbursement/:id",
    element: donationDisbursement,
  },
  {
    path: "/donation/donation/disbursement/:id/new",
    element: donationDisbursementNew,
  },
  {
    path: "/donation/donation/disbursement/:id/:disbursementId",
    element: donationDisbursement,
  },
  {
    path: "/donation/donation/new",
    name: "Tambah Data Donasi",
    element: donationAdd,
  },
  {
    path: "/donation/donation/:id",
    name: "Detail Data Donasi",
    element: donationEdit,
  },
  {
    path: "/donation/zakat",
    name: "Data Zakat",
    element: zakat,
  },
  {
    path: "/donation/zakat/muzakki/:id",
    element: zakatBenefactor,
  },
  {
    path: "/donation/zakat/progress/:id",
    element: zakatProgress,
  },
  {
    path: "/donation/zakat/progress/:id/new",
    element: zakatProgressNew,
  },
  {
    path: "/donation/zakat/progress/:id/:progressId",
    element: zakatProgressEdit,
  },
  {
    path: "/donation/zakat/disbursement/:id",
    element: zakatDisbursement,
  },
  {
    path: "/donation/zakat/disbursement/:id/new",
    element: zakatDisbursementNew,
  },
  {
    path: "/donation/zakat/disbursement/:id/:disbursementId",
    element: zakatDisbursement,
  },
  {
    path: "/donation/zakat/new",
    name: "Tambah Data Zakat",
    element: zakatAdd,
  },
  {
    path: "/donation/zakat/:id",
    name: "Detail Data Zakat",
    element: zakatEdit,
  },
  {
    path: "/donation/properties-menu",
    element: propertiesMenuDonation,
  },
];

export default donationRoutes;
