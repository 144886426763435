import React from "react";
const cardConfig = React.lazy(() => import("../views/pages/card/cardConfig"));
const cardTemplate = React.lazy(() =>
  import("../views/pages/card/cardTemplate")
);
const cardData = React.lazy(() => import("../views/pages/card/cardData"));
const summaryTransaction = React.lazy(() =>
  import("../views/pages/card/transaction/cardSummaryTransaction")
);

const newCardTemplate = React.lazy(() =>
  import("../views/pages/card/newCardTemplate")
);
const editCardTemplate = React.lazy(() =>
  import("../views/pages/card/editCardTemplate")
);

const newCardData = React.lazy(() => import("../views/pages/card/newCard"));
const editCardData = React.lazy(() => import("../views/pages/card/editCard"));
const detailCard = React.lazy(() => import("../views/pages/card/detailCard"));

const chargeCardPrice = React.lazy(() =>
  import("../views/pages/card/charge/cardPrice")
);
const chargeCardBalanceTopup = React.lazy(() =>
  import("../views/pages/card/charge/cardBalanceTopup")
);
const chargeCardBalanceWithdraw = React.lazy(() =>
  import("../views/pages/card/charge/cardBalanceWithdraw")
);

const transactionCardTopupBalance = React.lazy(() =>
  import("../views/pages/card/transaction/cardTopupBalance")
);
const transactionCardWithdrawBalance = React.lazy(() =>
  import("../views/pages/card/transaction/cardWithdrawBalance")
);
const transactionCardTopupBalanceMerchant = React.lazy(() =>
  import("../views/pages/card/transaction/cardTopupBalanceMerchant")
);
const transactionCardWithdrawBalanceMerhcant = React.lazy(() =>
  import("../views/pages/card/transaction/cardWithdrawBalanceMerchant")
);
const transactionCardCreateCard = React.lazy(() =>
  import("../views/pages/card/transaction/cardTransactionCreateCard")
);
const transactionCard = React.lazy(() =>
  import("../views/pages/card/transaction/cardTransaction")
);

const cardMemberConfig = React.lazy(() =>
  import("../views/pages/card/cardMemberConfig")
);
const chargeCardTransaction = React.lazy(() =>
  import("../views/pages/card/charge/cardTransaction")
);
const chargeCardBalanceTopupViaMerchant = React.lazy(() =>
  import("../views/pages/card/charge/cardBalanceTopupMerchant")
);
const chargeCardBalanceWithdrawViaMerchant = React.lazy(() =>
  import("../views/pages/card/charge/cardBalanceWithdrawViaMerchant")
);
const newTemplateSubCard = React.lazy(() =>
  import("../views/pages/card/subCard/newTemplate")
);
const editTemplateSubCard = React.lazy(() =>
  import("../views/pages/card/subCard/editTemplate")
);
const subCardsData = React.lazy(() =>
  import("../views/pages/card/subCard/subCardData")
);
const dataAidHelper = React.lazy(() =>
  import("../views/pages/card/aidHelper/data/data")
);
const newDataAidHelper = React.lazy(() =>
  import("../views/pages/card/aidHelper/data/new")
);
const editDataAidHelper = React.lazy(() =>
  import("../views/pages/card/aidHelper/data/edit")
);
const aidReceipentData = React.lazy(() =>
  import("../views/pages/card/aidHelper/aidRecipients/dataReceipent")
);
const detailTransactionAid = React.lazy(() =>
  import("../views/pages/card/aidHelper/data/detail")
);
const chooseCardAdminFees = React.lazy(() =>
  import("../views/pages/card/charge/chooseCharge")
);

const cardRoutes = [
  { path: "/card-config", name: "Konfigurasi Kartu", element: cardConfig },
  {
    path: "/card-member-config",
    name: "Konfigurasi Kartu",
    element: cardMemberConfig,
  },
  { path: "card/card-template", name: "Template Kartu", element: cardTemplate },
  { path: "/card-data", name: "Data Kartu Virtual", element: cardData },
  {
    path: "/card-summary-transaction",
    name: "Data Transaksi Kartu",
    element: summaryTransaction,
  },

  {
    path: "card/card-template/new",
    name: "Template Kartu Baru",
    element: newCardTemplate,
  },
  {
    path: "card/card-template/edit",
    name: "Edit Template Kartu",
    element: editCardTemplate,
  },

  { path: "/card-data/new", name: "Tambah Kartu Baru", element: newCardData },
  { path: "/card-data/edit/:id", name: "Edit Kartu", element: editCardData },
  { path: "/card-data/detail/:id", name: "Detail Kartu", element: detailCard },

  {
    path: "/config-charge/card-price",
    name: "Konfigurasi Harga Pembuatan Kartu",
    element: chargeCardPrice,
  },
  {
    path: "/config-charge/card-balance-topup-member",
    name: "Konfigurasi",
    element: chargeCardBalanceTopup,
  },
  {
    path: "/config-charge/card-balance-withdraw-member",
    name: "Konfigurasi",
    element: chargeCardBalanceWithdraw,
  },

  {
    path: "/card-summary-transaction/topup-balance",
    name: "Data Transaksi Kartu",
    element: transactionCardTopupBalance,
  },
  {
    path: "/card-summary-transaction/withdraw-balance",
    name: "Data Transaksi Kartu",
    element: transactionCardWithdrawBalance,
  },
  {
    path: "/card-summary-transaction/topup-balance-merchant",
    name: "Data Transaksi Kartu",
    element: transactionCardTopupBalanceMerchant,
  },
  {
    path: "/card-summary-transaction/withdraw-balance-merchant",
    name: "Data Transaksi Kartu",
    element: transactionCardWithdrawBalanceMerhcant,
  },
  {
    path: "/card-summary-transaction/create-card",
    name: "Data Transaksi Kartu",
    element: transactionCardCreateCard,
  },
  {
    path: "/card-summary-transaction/card-transaction",
    name: "Data Transaksi Kartu",
    element: transactionCard,
  },
  {
    path: "/config-charge/card-transaction",
    element: chargeCardTransaction,
  },
  {
    path: "/config-charge/card-balance-topup-merchant",
    element: chargeCardBalanceTopupViaMerchant,
  },
  {
    path: "/config-charge/card-balance-withdraw-merchant",
    element: chargeCardBalanceWithdrawViaMerchant,
  },
  {
    path: "/card/card-template/subcard/new",
    element: newTemplateSubCard,
  },
  {
    path: "/card/card-template/subcard/edit",
    element: editTemplateSubCard,
  },
  {
    path: "/card-data/subcard/:id",
    element: subCardsData,
  },
  {
    path: "/card/distribution-aid/data",
    element: dataAidHelper,
  },
  {
    path: "/card/distribution-aid/data/new",
    element: newDataAidHelper,
  },
  {
    path: "/card/distribution-aid/data/edit/:id",
    element: editDataAidHelper,
  },
  {
    path: "/card/distribution-aid/aid-recipients",
    element: aidReceipentData,
  },
  {
    path: "/card/distribution-aid/data/detail-transaction/:id",
    element: detailTransactionAid,
  },
  {
    path: "/card/card-admin-fees",
    element: chooseCardAdminFees,
  },
];

export default cardRoutes;
