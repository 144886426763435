import React from "react";
const financialManagement = React.lazy(() =>
  import("../views/pages/financialManagement/index")
);
const companyWithdrawal = React.lazy(() =>
  import("../views/pages/financialManagement/companyWithdrawal")
);
const detailCompanyWithdrawal = React.lazy(() =>
  import("../views/pages/financialManagement/detailWithdraw")
);
const companyWithdrawalBalanceWithdraw = React.lazy(() =>
  import("../views/pages/financialManagement/withdrawBalance")
);
const paymentHistoryTrxMonthly = React.lazy(() =>
  import("../views/pages/financialManagement/paymentHistoryTrx")
);

const financialRoutes = [
  {
    path: "/financial-management",
    element: financialManagement,
  },
  {
    path: "/financial-management/balance-withdrawal",
    element: companyWithdrawal,
  },
  {
    path: "/financial-management/balance-withdrawal/detail/:id",
    element: detailCompanyWithdrawal,
  },
  {
    path: "/financial-management/balance-withdrawal/withdraw-balance",
    element: companyWithdrawalBalanceWithdraw,
  },
  {
    path: "/financial-management/transactional-bill-payment-history/:id",
    element: paymentHistoryTrxMonthly,
  },
];

export default financialRoutes;
