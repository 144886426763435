import { createSlice } from "@reduxjs/toolkit";
// import User from "../../models/user"
import {
  saveState,
  loadState,
  removeState,
} from "../browser-storage/browser-storage";

// type InitialState = {
//   user: User
// }
const type = "profile";

const initialState = {
  profile: loadState(type),
};

export const profile = createSlice({
  name: type,
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
      saveState(type, action.payload);
    },
    removeProfile: (state) => {
      state.profile = "";
      removeState(type);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProfile, removeProfile } = profile.actions;

export default profile.reducer;
