import React from "react";
const notificationNews = React.lazy(() =>
  import("../views/pages/notification/notification")
);
const notificationDetailNews = React.lazy(() =>
  import("../views/pages/notification/detailNews")
);
const recordNotification = React.lazy(() =>
  import(
    "../views/pages/notificationManagement/recordNotification/recordNotification"
  )
);
const notificationTemplate = React.lazy(() =>
  import(
    "../views/pages/notificationManagement/notificationTemplate/notificationTemplate"
  )
);
const detailNotificationTemplate = React.lazy(() =>
  import("../views/pages/notificationManagement/notificationTemplate/detail")
);
const editNotificationTemplate = React.lazy(() =>
  import("../views/pages/notificationManagement/notificationTemplate/editData")
);

const notificationRoutes = [
  {
    path: "/notification",
    element: notificationNews,
  },
  {
    path: "/notification/detail-news/:id",
    element: notificationDetailNews,
  },
  {
    path: "/notification-management/record-notification",
    element: recordNotification,
  },
  {
    path: "/notification-management/template-configuration",
    element: notificationTemplate,
  },
  {
    path: "/notification-management/template-configuration/detail/:id",
    element: detailNotificationTemplate,
  },
  {
    path: "/notification-management/template-configuration/edit/:id",
    element: editNotificationTemplate,
  },
];

export default notificationRoutes;
