// import ErrorHandler from "./errorHandler";
import Config from "../../config/Config.ts";
// import { setupInterceptorsTo } from './interceptors.ts'
import axios from "axios";
import {
  check,
  createArrayEntityFromObject,
  createQueryUrl,
  getEntity,
} from "../../config/global";
// setupInterceptorsTo(axios)

const baseUrl = Config.BaseUrl;

export const download = (url, query, body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}${url}?${createQueryUrl(
          getEntity(createArrayEntityFromObject(query), query),
          "&"
        )}`,
        body,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const downloadData = (url, query) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrl}${url}?${createQueryUrl(
          getEntity(createArrayEntityFromObject(query), query),
          "&"
        )}`,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const comboColumn = (url) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}${url}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const comboColumnVcard = (url) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}${url}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const comboColumnAid = (url) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}${url}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const apiDownloadPdf = (url, query, body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}${url}?${createQueryUrl(
          getEntity(createArrayEntityFromObject(query), query),
          "&"
        )}`,
        body,
        {
          // responseType: "blob",
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
