const Icons = {
  sideBar: {
    dashboard: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M18.3333 7.09996V3.31663C18.3333 2.14163 17.8 1.66663 16.475 1.66663H13.1083C11.7833 1.66663 11.25 2.14163 11.25 3.31663V7.09163C11.25 8.27496 11.7833 8.74163 13.1083 8.74163H16.475C17.8 8.74996 18.3333 8.27496 18.3333 7.09996Z"
          fill="#A3AED0"
        />
        <path
          d="M18.3333 16.475V13.1083C18.3333 11.7833 17.8 11.25 16.475 11.25H13.1083C11.7833 11.25 11.25 11.7833 11.25 13.1083V16.475C11.25 17.8 11.7833 18.3333 13.1083 18.3333H16.475C17.8 18.3333 18.3333 17.8 18.3333 16.475Z"
          fill="#A3AED0"
        />
        <path
          d="M8.75002 7.09996V3.31663C8.75002 2.14163 8.21669 1.66663 6.89169 1.66663H3.52502C2.20002 1.66663 1.66669 2.14163 1.66669 3.31663V7.09163C1.66669 8.27496 2.20002 8.74163 3.52502 8.74163H6.89169C8.21669 8.74996 8.75002 8.27496 8.75002 7.09996Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.4"
          d="M8.75002 16.475V13.1083C8.75002 11.7833 8.21669 11.25 6.89169 11.25H3.52502C2.20002 11.25 1.66669 11.7833 1.66669 13.1083V16.475C1.66669 17.8 2.20002 18.3333 3.52502 18.3333H6.89169C8.21669 18.3333 8.75002 17.8 8.75002 16.475Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    infoPerusahaan: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M1.75 18.3333V5.02498C1.75 3.34998 2.58338 2.5083 4.24171 2.5083H9.43336C11.0917 2.5083 11.9167 3.34998 11.9167 5.02498V18.3333"
          fill="#A3AED0"
        />
        <path
          d="M8.95832 7.5H4.83331C4.49165 7.5 4.20831 7.21667 4.20831 6.875C4.20831 6.53333 4.49165 6.25 4.83331 6.25H8.95832C9.29999 6.25 9.58332 6.53333 9.58332 6.875C9.58332 7.21667 9.29999 7.5 8.95832 7.5Z"
          fill="#A3AED0"
        />
        <path
          d="M8.95832 10.625H4.83331C4.49165 10.625 4.20831 10.3417 4.20831 10C4.20831 9.65833 4.49165 9.375 4.83331 9.375H8.95832C9.29999 9.375 9.58332 9.65833 9.58332 10C9.58332 10.3417 9.29999 10.625 8.95832 10.625Z"
          fill="#A3AED0"
        />
        <path
          d="M6.875 18.9584C6.53333 18.9584 6.25 18.675 6.25 18.3334V15.2084C6.25 14.8667 6.53333 14.5834 6.875 14.5834C7.21667 14.5834 7.5 14.8667 7.5 15.2084V18.3334C7.5 18.675 7.21667 18.9584 6.875 18.9584Z"
          fill="#A3AED0"
        />
        <path
          d="M19.1666 17.7084H17.275V15.2084C18.0666 14.95 18.6416 14.2084 18.6416 13.3334V11.6667C18.6416 10.575 17.75 9.68335 16.6583 9.68335C15.5666 9.68335 14.675 10.575 14.675 11.6667V13.3334C14.675 14.2 15.2416 14.9333 16.0166 15.2V17.7084H0.833313C0.491646 17.7084 0.208313 17.9917 0.208313 18.3334C0.208313 18.675 0.491646 18.9584 0.833313 18.9584H16.6083C16.625 18.9584 16.6333 18.9667 16.65 18.9667C16.6666 18.9667 16.675 18.9584 16.6917 18.9584H19.1666C19.5083 18.9584 19.7916 18.675 19.7916 18.3334C19.7916 17.9917 19.5083 17.7084 19.1666 17.7084Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    pengelolaanKeuangan: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M15.0333 11.2917C14.6833 11.6334 14.4833 12.125 14.5333 12.65C14.6083 13.55 15.4333 14.2084 16.3333 14.2084H17.9166V15.2C17.9166 16.925 16.5083 18.3334 14.7833 18.3334H5.21665C3.49165 18.3334 2.08331 16.925 2.08331 15.2V9.59172C2.08331 7.86672 3.49165 6.45837 5.21665 6.45837H14.7833C16.5083 6.45837 17.9166 7.86672 17.9166 9.59172V10.7917H16.2333C15.7666 10.7917 15.3416 10.975 15.0333 11.2917Z"
          fill="#A3AED0"
        />
        <path
          d="M12.375 3.29165V6.4583H5.21665C3.49165 6.4583 2.08331 7.86665 2.08331 9.59165V6.53333C2.08331 5.54166 2.69165 4.65829 3.61665 4.30829L10.2333 1.80829C11.2666 1.42496 12.375 2.18331 12.375 3.29165Z"
          fill="#A3AED0"
        />
        <path
          d="M18.8 11.6416V13.3583C18.8 13.8166 18.4334 14.1916 17.9667 14.2083H16.3334C15.4334 14.2083 14.6084 13.5499 14.5334 12.6499C14.4834 12.1249 14.6834 11.6333 15.0334 11.2916C15.3417 10.9749 15.7667 10.7916 16.2334 10.7916H17.9667C18.4334 10.8083 18.8 11.1833 18.8 11.6416Z"
          fill="#A3AED0"
        />
        <path
          d="M11.6666 10.625H5.83331C5.49165 10.625 5.20831 10.3417 5.20831 10C5.20831 9.65833 5.49165 9.375 5.83331 9.375H11.6666C12.0083 9.375 12.2916 9.65833 12.2916 10C12.2916 10.3417 12.0083 10.625 11.6666 10.625Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    dataUser: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M10 18.3417C14.6024 18.3417 18.3334 14.6108 18.3334 10.0084C18.3334 5.40601 14.6024 1.67505 10 1.67505C5.39765 1.67505 1.66669 5.40601 1.66669 10.0084C1.66669 14.6108 5.39765 18.3417 10 18.3417Z"
          fill="#A3AED0"
        />
        <path
          d="M10 5.78333C8.275 5.78333 6.875 7.18332 6.875 8.90832C6.875 10.6 8.2 11.975 9.95833 12.025C9.98333 12.025 10.0167 12.025 10.0333 12.025C10.05 12.025 10.075 12.025 10.0917 12.025C10.1 12.025 10.1083 12.025 10.1083 12.025C11.7917 11.9667 13.1167 10.6 13.125 8.90832C13.125 7.18332 11.725 5.78333 10 5.78333Z"
          fill="#A3AED0"
        />
        <path
          d="M15.65 16.1333C14.1666 17.5 12.1833 18.3416 9.99998 18.3416C7.81664 18.3416 5.83331 17.5 4.34998 16.1333C4.54998 15.375 5.09164 14.6833 5.88331 14.15C8.15831 12.6333 11.8583 12.6333 14.1166 14.15C14.9166 14.6833 15.45 15.375 15.65 16.1333Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    lms: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M15.3167 10.7V14.8083C15.3167 15.8666 14.4917 16.9999 13.5 17.3333L10.8417 18.2166C10.375 18.375 9.61668 18.375 9.15835 18.2166L6.50002 17.3333C5.50002 16.9999 4.68335 15.8666 4.68335 14.8083L4.69168 10.7L8.37502 13.1C9.27502 13.6916 10.7583 13.6916 11.6583 13.1L15.3167 10.7Z"
          fill="#A3AED0"
        />
        <path
          d="M16.65 5.3833L11.6583 2.1083C10.7583 1.51663 9.27502 1.51663 8.37502 2.1083L3.35835 5.3833C1.75002 6.42497 1.75002 8.7833 3.35835 9.8333L4.69168 10.7L8.37502 13.1C9.27502 13.6916 10.7583 13.6916 11.6583 13.1L15.3167 10.7L16.4583 9.94997V12.5C16.4583 12.8416 16.7417 13.125 17.0833 13.125C17.425 13.125 17.7083 12.8416 17.7083 12.5V8.39997C18.0417 7.32497 17.7 6.07497 16.65 5.3833Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    dataNotifikasi: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M14.9834 8.99167V12.325C14.9834 12.5417 14.975 12.75 14.95 12.95C14.7584 15.2 13.4334 16.3167 10.9917 16.3167H10.6584C10.45 16.3167 10.25 16.4167 10.125 16.5833L9.12503 17.9167C8.68336 18.5083 7.96669 18.5083 7.52502 17.9167L6.52501 16.5833C6.41668 16.4417 6.17502 16.3167 5.99169 16.3167H5.65836C3.00003 16.3167 1.66669 15.6583 1.66669 12.325V8.99167C1.66669 6.55001 2.79169 5.22501 5.03336 5.03334C5.23336 5.00834 5.44169 5 5.65836 5H10.9917C13.65 5 14.9834 6.33334 14.9834 8.99167Z"
          fill="#A3AED0"
        />
        <path
          d="M8.32497 11.6667C7.8583 11.6667 7.49164 11.2917 7.49164 10.8333C7.49164 10.375 7.86664 10 8.32497 10C8.78331 10 9.15831 10.375 9.15831 10.8333C9.15831 11.2917 8.79164 11.6667 8.32497 11.6667Z"
          fill="#A3AED0"
        />
        <path
          d="M11.2417 11.6667C10.775 11.6667 10.4083 11.2917 10.4083 10.8333C10.4083 10.375 10.7833 10 11.2417 10C11.7 10 12.075 10.375 12.075 10.8333C12.075 11.2917 11.7 11.6667 11.2417 11.6667Z"
          fill="#A3AED0"
        />
        <path
          d="M5.41665 11.6667C4.94998 11.6667 4.58331 11.2917 4.58331 10.8333C4.58331 10.375 4.95831 10 5.41665 10C5.87498 10 6.24998 10.375 6.24998 10.8333C6.24998 11.2917 5.87498 11.6667 5.41665 11.6667Z"
          fill="#A3AED0"
        />
        <path
          d="M18.3167 5.6583V8.99163C18.3167 11.4416 17.1917 12.7583 14.95 12.95C14.975 12.75 14.9833 12.5416 14.9833 12.325V8.99163C14.9833 6.3333 13.65 4.99996 10.9917 4.99996H5.65833C5.44166 4.99996 5.23333 5.0083 5.03333 5.0333C5.22499 2.79163 6.54999 1.66663 8.99166 1.66663H14.325C16.9833 1.66663 18.3167 2.99997 18.3167 5.6583Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    infoBerita: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.6667 10.1667V11.5834C16.6667 14.2084 15.1667 15.3334 12.9167 15.3334H5.41669C3.16669 15.3334 1.66669 14.2084 1.66669 11.5834V7.08337C1.66669 4.45837 3.16669 3.33337 5.41669 3.33337H7.66669C7.55835 3.65004 7.50002 4.00004 7.50002 4.37504V7.62506C7.50002 8.43339 7.76669 9.11672 8.24169 9.59172C8.71669 10.0667 9.40002 10.3334 10.2084 10.3334V11.4917C10.2084 11.9167 10.6917 12.1751 11.05 11.9417L13.4584 10.3334H15.625C16 10.3334 16.35 10.2751 16.6667 10.1667Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.4"
          d="M18.3333 4.37496V7.62498C18.3333 8.86665 17.7 9.79997 16.6667 10.1666C16.35 10.275 16 10.3333 15.625 10.3333H13.4583L11.05 11.9416C10.6917 12.175 10.2083 11.9166 10.2083 11.4916V10.3333C9.4 10.3333 8.71667 10.0666 8.24167 9.59163C7.76667 9.11663 7.5 8.43331 7.5 7.62498V4.37496C7.5 3.99996 7.55833 3.64996 7.66667 3.33329C8.03333 2.29996 8.96667 1.66663 10.2083 1.66663H15.625C17.25 1.66663 18.3333 2.74996 18.3333 4.37496Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.4"
          d="M12.1667 17.7084H9.79169V15.3334C9.79169 14.9917 9.50835 14.7084 9.16669 14.7084C8.82502 14.7084 8.54169 14.9917 8.54169 15.3334V17.7084H6.16669C5.82502 17.7084 5.54169 17.9917 5.54169 18.3334C5.54169 18.675 5.82502 18.9584 6.16669 18.9584H12.1667C12.5084 18.9584 12.7917 18.675 12.7917 18.3334C12.7917 17.9917 12.5084 17.7084 12.1667 17.7084Z"
          fill="#A3AED0"
        />
        <path
          d="M12.8666 6.66663C12.5166 6.66663 12.2416 6.38329 12.2416 6.04163C12.2416 5.69996 12.525 5.41663 12.8666 5.41663C13.2083 5.41663 13.4916 5.69996 13.4916 6.04163C13.4916 6.38329 13.2083 6.66663 12.8666 6.66663Z"
          fill="#A3AED0"
        />
        <path
          d="M15.2083 6.66663C14.8583 6.66663 14.5833 6.38329 14.5833 6.04163C14.5833 5.69996 14.8666 5.41663 15.2083 5.41663C15.55 5.41663 15.8333 5.69996 15.8333 6.04163C15.8333 6.38329 15.55 6.66663 15.2083 6.66663Z"
          fill="#A3AED0"
        />
        <path
          d="M10.5167 6.66663C10.1667 6.66663 9.89166 6.38329 9.89166 6.04163C9.89166 5.69996 10.175 5.41663 10.5167 5.41663C10.8583 5.41663 11.1417 5.69996 11.1417 6.04163C11.1417 6.38329 10.8667 6.66663 10.5167 6.66663Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    subCompany: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          d="M8.42501 3.3335C8.35834 3.5835 8.33335 3.8585 8.33335 4.16683V6.66683H4.16669V5.00016C4.16669 4.0835 4.91669 3.3335 5.83335 3.3335H8.42501Z"
          fill="#A3AED0"
        />
        <path
          d="M8.33335 15.8332V6.6665H4.16669C2.50002 6.6665 1.66669 7.49984 1.66669 9.1665V15.8332C1.66669 17.4998 2.50002 18.3332 4.16669 18.3332H10.8334C9.16669 18.3332 8.33335 17.4998 8.33335 15.8332ZM5.62502 14.1665C5.62502 14.5082 5.34169 14.7915 5.00002 14.7915C4.65835 14.7915 4.37502 14.5082 4.37502 14.1665V10.8332C4.37502 10.4915 4.65835 10.2082 5.00002 10.2082C5.34169 10.2082 5.62502 10.4915 5.62502 10.8332V14.1665Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.4"
          d="M11.6666 18.3332H10.8333C9.16665 18.3332 8.33331 17.4998 8.33331 15.8332V4.1665C8.33331 2.49984 9.16665 1.6665 10.8333 1.6665H15.8333C17.5 1.6665 18.3333 2.49984 18.3333 4.1665V15.8332C18.3333 17.4998 17.5 18.3332 15.8333 18.3332H15"
          fill="#A3AED0"
        />
        <path
          d="M15 14.9998V18.3332H11.6667V14.9998C11.6667 14.5415 12.0417 14.1665 12.5 14.1665H14.1667C14.625 14.1665 15 14.5415 15 14.9998Z"
          fill="#A3AED0"
        />
        <path
          d="M11.6667 11.4582C11.325 11.4582 11.0417 11.1748 11.0417 10.8332V6.6665C11.0417 6.32484 11.325 6.0415 11.6667 6.0415C12.0084 6.0415 12.2917 6.32484 12.2917 6.6665V10.8332C12.2917 11.1748 12.0084 11.4582 11.6667 11.4582Z"
          fill="#A3AED0"
        />
        <path
          d="M15 11.4582C14.6583 11.4582 14.375 11.1748 14.375 10.8332V6.6665C14.375 6.32484 14.6583 6.0415 15 6.0415C15.3417 6.0415 15.625 6.32484 15.625 6.6665V10.8332C15.625 11.1748 15.3417 11.4582 15 11.4582Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    saldoPembayaran: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M13.4917 1.66663H6.50835C3.47502 1.66663 1.66669 3.47496 1.66669 6.50829V13.4833C1.66669 16.525 3.47502 18.3333 6.50835 18.3333H13.4834C16.5167 18.3333 18.325 16.525 18.325 13.4916V6.50829C18.3334 3.47496 16.525 1.66663 13.4917 1.66663Z"
          fill="#A3AED0"
        />
        <path
          d="M11.8833 10L10.625 9.55833V6.73333H10.925C11.6 6.73333 12.15 7.325 12.15 8.05C12.15 8.39167 12.4333 8.675 12.775 8.675C13.1167 8.675 13.4 8.39167 13.4 8.05C13.4 6.63333 12.2917 5.48333 10.925 5.48333H10.625V5C10.625 4.65833 10.3417 4.375 10 4.375C9.65834 4.375 9.37501 4.65833 9.37501 5V5.48333H8.83334C7.60001 5.48333 6.59167 6.525 6.59167 7.8C6.59167 9.29167 7.45834 9.76667 8.11667 10L9.37501 10.4417V13.2583H9.07501C8.40001 13.2583 7.85001 12.6667 7.85001 11.9417C7.85001 11.6 7.56667 11.3167 7.22501 11.3167C6.88334 11.3167 6.60001 11.6 6.60001 11.9417C6.60001 13.3583 7.70834 14.5083 9.07501 14.5083H9.37501V15C9.37501 15.3417 9.65834 15.625 10 15.625C10.3417 15.625 10.625 15.3417 10.625 15V14.5167H11.1667C12.4 14.5167 13.4083 13.475 13.4083 12.2C13.4 10.7 12.5333 10.225 11.8833 10ZM8.53334 8.825C8.10834 8.675 7.85001 8.53333 7.85001 7.80833C7.85001 7.21667 8.29167 6.74167 8.84167 6.74167H9.38334V9.125L8.53334 8.825ZM11.1667 13.2667H10.625V10.8833L11.4667 11.175C11.8917 11.325 12.15 11.4667 12.15 12.1917C12.15 12.7833 11.7083 13.2667 11.1667 13.2667Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    topupVa: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M15.675 5.20831H16.8084C16.6501 4.98331 16.4834 4.77498 16.3084 4.56665L15.675 5.20831Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.4"
          d="M15.4333 3.68335C15.225 3.50835 15.0167 3.34168 14.7917 3.18335V4.31668L15.4333 3.68335Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.4"
          d="M14.1667 6.45841C14.0834 6.45841 14.0083 6.44175 13.925 6.40842C13.775 6.34175 13.65 6.22508 13.5833 6.06675C13.55 5.99175 13.5333 5.90841 13.5333 5.82508V2.49175C13.5333 2.48341 13.5417 2.47508 13.5417 2.45841C12.4667 1.95841 11.2667 1.66675 10 1.66675C5.40002 1.66675 1.66669 5.40008 1.66669 10.0001C1.66669 14.6001 5.40002 18.3334 10 18.3334C14.6 18.3334 18.3334 14.6001 18.3334 10.0001C18.3334 8.73341 18.0417 7.53341 17.5334 6.45008C17.525 6.45008 17.5167 6.45841 17.5 6.45841H14.1667Z"
          fill="#A3AED0"
        />
        <path
          d="M16.3167 4.56662L18.775 2.10828C19.0167 1.86662 19.0167 1.46662 18.775 1.22495C18.5334 0.983285 18.1334 0.983285 17.8917 1.22495L15.4333 3.68328C15.75 3.95828 16.0417 4.25829 16.3167 4.56662Z"
          fill="#A3AED0"
        />
        <path
          d="M14.7916 2.5C14.7916 2.15833 14.5083 1.875 14.1666 1.875C13.8333 1.875 13.5667 2.14167 13.55 2.46667C13.9833 2.675 14.4 2.90833 14.7916 3.18333V2.5Z"
          fill="#A3AED0"
        />
        <path
          d="M18.125 5.83325C18.125 5.49159 17.8417 5.20825 17.5 5.20825H16.8083C17.0833 5.59992 17.325 6.01658 17.525 6.44992C17.8583 6.43325 18.125 6.16659 18.125 5.83325Z"
          fill="#A3AED0"
        />
        <path
          d="M11.4584 9.85L10.625 9.55833V7.70833H10.6917C11.1167 7.70833 11.4584 8.08333 11.4584 8.54167C11.4584 8.88333 11.7417 9.16667 12.0834 9.16667C12.425 9.16667 12.7084 8.88333 12.7084 8.54167C12.7084 7.39167 11.8083 6.45833 10.6917 6.45833H10.625V6.25C10.625 5.90833 10.3417 5.625 10 5.625C9.65835 5.625 9.37502 5.90833 9.37502 6.25V6.45833H9.12503C8.1167 6.45833 7.29169 7.30833 7.29169 8.35833C7.29169 9.575 8.00002 9.96667 8.54169 10.1583L9.37502 10.45V12.3H9.30836C8.88336 12.3 8.54169 11.925 8.54169 11.4667C8.54169 11.125 8.25835 10.8417 7.91669 10.8417C7.57502 10.8417 7.29169 11.125 7.29169 11.4667C7.29169 12.6167 8.1917 13.55 9.30836 13.55H9.37502V13.7583C9.37502 14.1 9.65835 14.3833 10 14.3833C10.3417 14.3833 10.625 14.1 10.625 13.7583V13.55H10.875C11.8833 13.55 12.7084 12.7 12.7084 11.65C12.7084 10.425 12 10.0333 11.4584 9.85ZM8.95001 8.96667C8.66668 8.86667 8.54169 8.80833 8.54169 8.35C8.54169 7.99166 8.80836 7.7 9.12503 7.7H9.37502V9.10833L8.95001 8.96667ZM10.875 12.2917H10.625V10.8833L11.05 11.0333C11.3334 11.1333 11.4584 11.1917 11.4584 11.65C11.4584 12 11.1917 12.2917 10.875 12.2917Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    transferBank: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.475 0.833252H15.025C13.9666 0.833252 13.3333 1.46659 13.3333 2.52492V4.97492C13.3333 6.03325 13.9666 6.66659 15.025 6.66659H17.475C18.5333 6.66659 19.1666 6.03325 19.1666 4.97492V2.52492C19.1666 1.46659 18.5333 0.833252 17.475 0.833252ZM17.6583 3.59159C17.5583 3.69159 17.425 3.74159 17.2916 3.74159C17.1583 3.74159 17.025 3.69159 16.925 3.59159L16.775 3.44159V5.30825C16.775 5.59992 16.5416 5.83325 16.25 5.83325C15.9583 5.83325 15.725 5.59992 15.725 5.30825V3.44159L15.575 3.59159C15.375 3.79159 15.0416 3.79159 14.8416 3.59159C14.6416 3.39159 14.6416 3.05825 14.8416 2.85825L15.8833 1.81659C15.925 1.77492 15.9833 1.74159 16.0416 1.71659C16.0583 1.70825 16.075 1.70825 16.0916 1.69992C16.1333 1.68325 16.175 1.67492 16.225 1.67492C16.2416 1.67492 16.2583 1.67492 16.275 1.67492C16.3333 1.67492 16.3833 1.68325 16.4416 1.70825C16.45 1.70825 16.45 1.70825 16.4583 1.70825C16.5166 1.73325 16.5666 1.76659 16.6083 1.80825C16.6166 1.81659 16.6166 1.81659 16.625 1.81659L17.6666 2.85825C17.8666 3.05825 17.8666 3.39159 17.6583 3.59159Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.4"
          d="M18.3334 6.47492V7.49992H1.66669V6.28325C1.66669 4.37492 3.21669 2.83325 5.12502 2.83325H13.3334V4.97492C13.3334 6.03325 13.9667 6.66659 15.025 6.66659H17.475C17.8084 6.66659 18.0917 6.60825 18.3334 6.47492Z"
          fill="#A3AED0"
        />
        <path
          d="M1.66669 7.5V13.7167C1.66669 15.625 3.21669 17.1667 5.12502 17.1667H14.875C16.7834 17.1667 18.3334 15.625 18.3334 13.7167V7.5H1.66669ZM6.66669 14.375H5.00002C4.65835 14.375 4.37502 14.0917 4.37502 13.75C4.37502 13.4083 4.65835 13.125 5.00002 13.125H6.66669C7.00835 13.125 7.29169 13.4083 7.29169 13.75C7.29169 14.0917 7.00835 14.375 6.66669 14.375ZM12.0834 14.375H8.75002C8.40835 14.375 8.12502 14.0917 8.12502 13.75C8.12502 13.4083 8.40835 13.125 8.75002 13.125H12.0834C12.425 13.125 12.7084 13.4083 12.7084 13.75C12.7084 14.0917 12.425 14.375 12.0834 14.375Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    withdrawMerchant: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.60831 13.5667C6.52497 13.475 6.43329 13.3833 6.33329 13.3083C5.74996 12.8 4.99998 12.5 4.16665 12.5C3.57498 12.5 3.01663 12.6583 2.53329 12.9333C2.37496 13.0167 2.22498 13.1167 2.08331 13.2333C1.31665 13.8417 0.833313 14.7833 0.833313 15.8333C0.833313 16.4583 1.00833 17.05 1.31666 17.55C1.39166 17.6833 1.48333 17.8083 1.58333 17.925C1.6 17.95 1.62496 17.9667 1.64162 17.9917C2.24162 18.7084 3.14998 19.1667 4.16665 19.1667C5.00831 19.1667 5.77498 18.8583 6.35832 18.3333C6.39165 18.3083 6.41663 18.2834 6.44163 18.25C6.66663 18.0584 6.8583 17.8167 7.01663 17.55C7.32497 17.05 7.49998 16.4583 7.49998 15.8333C7.49998 14.9583 7.15831 14.1667 6.60831 13.5667ZM5.41665 16.4417H2.92499C2.58332 16.4417 2.29999 16.1583 2.29999 15.8167C2.29999 15.475 2.58332 15.1917 2.92499 15.1917H5.41665C5.75831 15.1917 6.04165 15.475 6.04165 15.8167C6.04165 16.1583 5.75831 16.4417 5.41665 16.4417Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.4"
          d="M17.5416 9.75826V14.7499C17.5416 17.0499 15.675 18.9166 13.375 18.9166H6.09165C5.89999 18.9166 5.70833 18.8999 5.52499 18.8749C5.83333 18.7416 6.10833 18.5582 6.35833 18.3332C6.39166 18.3082 6.41664 18.2833 6.44164 18.2499C6.66664 18.0583 6.85831 17.8166 7.01664 17.5499C7.32498 17.0499 7.49999 16.4582 7.49999 15.8332C7.49999 14.9582 7.15832 14.1666 6.60832 13.5666C6.52498 13.4749 6.4333 13.3832 6.3333 13.3082C5.74997 12.7999 4.99999 12.4999 4.16666 12.4999C3.57499 12.4999 3.01664 12.6583 2.5333 12.9333C2.37497 13.0166 2.22499 13.1166 2.08332 13.2332C2.02499 13.2749 1.97499 13.3249 1.92499 13.3749V9.8166C2.55832 10.4999 3.45833 10.8916 4.43333 10.8916C5.48333 10.8916 6.49165 10.3666 7.12498 9.5249C7.69165 10.3666 8.65832 10.8916 9.73332 10.8916C10.8 10.8916 11.75 10.3916 12.325 9.55822C12.9666 10.3832 13.9583 10.8916 14.9916 10.8916C16 10.8916 16.9166 10.4833 17.5416 9.75826Z"
          fill="#A3AED0"
        />
        <path
          d="M12.225 1.30835H7.22496L6.6083 7.44167C6.5583 8.00834 6.64161 8.54169 6.84995 9.02502C7.33328 10.1584 8.46663 10.8917 9.7333 10.8917C11.0166 10.8917 12.125 10.175 12.625 9.03337C12.775 8.67503 12.8666 8.25836 12.875 7.83336V7.67503L12.225 1.30835Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.6"
          d="M18.3666 7.15841L18.1333 4.84172C17.7833 2.32505 16.6416 1.30005 14.2 1.30005H11L11.6167 7.55005C11.625 7.63338 11.6333 7.72507 11.6333 7.8834C11.6833 8.31674 11.8166 8.71673 12.0166 9.07506C12.6166 10.1751 13.7833 10.8834 15 10.8834C16.1083 10.8834 17.1083 10.3917 17.7333 9.52504C18.225 8.86671 18.45 8.02507 18.3666 7.15841Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.6"
          d="M5.22502 1.30835C2.77502 1.30835 1.64165 2.33332 1.28332 4.87499L1.05835 7.16666C0.975017 8.05832 1.21669 8.92503 1.74169 9.60003C2.37502 10.425 3.35001 10.8917 4.43334 10.8917C5.65001 10.8917 6.81668 10.1833 7.40835 9.1C7.62501 8.725 7.76666 8.29165 7.80833 7.84165L8.45835 1.31669H5.22502V1.30835Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    transferMember: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M17.575 17.9167C17.575 18.1501 17.3916 18.3334 17.1583 18.3334H2.84165C2.60832 18.3334 2.42499 18.1501 2.42499 17.9167C2.42499 14.4667 5.82498 11.6667 9.99998 11.6667C10.8583 11.6667 11.6917 11.7834 12.4583 12.0084C11.9667 12.5917 11.6667 13.3501 11.6667 14.1667C11.6667 14.7917 11.8416 15.3834 12.15 15.8834C12.3166 16.1667 12.5333 16.4251 12.7833 16.6417C13.3666 17.1751 14.1417 17.5001 15 17.5001C15.9333 17.5001 16.775 17.1167 17.375 16.5001C17.5083 16.9501 17.575 17.4251 17.575 17.9167Z"
          fill="#A3AED0"
        />
        <path
          d="M17.475 11.9416C16.875 11.2583 15.9834 10.8333 15 10.8333C14.0667 10.8333 13.2167 11.2166 12.6084 11.8416C12.025 12.4416 11.6667 13.2666 11.6667 14.1666C11.6667 14.7916 11.8417 15.3832 12.15 15.8832C12.3167 16.1666 12.5334 16.4249 12.7834 16.6416C13.3667 17.1749 14.1417 17.4999 15 17.4999C16.2167 17.4999 17.275 16.8499 17.85 15.8832C18.025 15.5999 18.1584 15.2749 18.2334 14.9416C18.3 14.6916 18.3334 14.4333 18.3334 14.1666C18.3334 13.3166 18.0084 12.5333 17.475 11.9416ZM16.25 14.7749H15.625V15.4249C15.625 15.7666 15.3417 16.0499 15 16.0499C14.6584 16.0499 14.375 15.7666 14.375 15.4249V14.7749H13.75C13.4084 14.7749 13.125 14.4916 13.125 14.1499C13.125 13.8082 13.4084 13.5249 13.75 13.5249H14.375V12.9333C14.375 12.5916 14.6584 12.3083 15 12.3083C15.3417 12.3083 15.625 12.5916 15.625 12.9333V13.5249H16.25C16.5917 13.5249 16.875 13.8082 16.875 14.1499C16.875 14.4916 16.5917 14.7749 16.25 14.7749Z"
          fill="#A3AED0"
        />
        <path
          d="M9.99998 10.0001C12.3012 10.0001 14.1666 8.1346 14.1666 5.83341C14.1666 3.53223 12.3012 1.66675 9.99998 1.66675C7.69879 1.66675 5.83331 3.53223 5.83331 5.83341C5.83331 8.1346 7.69879 10.0001 9.99998 10.0001Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    trxQris: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M2.30835 8.33341C1.95002 8.33341 1.66669 8.05008 1.66669 7.69175V5.76675C1.66669 3.50841 3.50835 1.66675 5.76669 1.66675H7.69169C8.05002 1.66675 8.33335 1.95008 8.33335 2.30841C8.33335 2.66675 8.05002 2.95008 7.69169 2.95008H5.76669C4.20835 2.95008 2.95002 4.21675 2.95002 5.76675V7.69175C2.95002 8.05008 2.65835 8.33341 2.30835 8.33341Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.4"
          d="M17.6917 8.33341C17.3417 8.33341 17.05 8.05008 17.05 7.69175V5.76675C17.05 4.20841 15.7834 2.95008 14.2334 2.95008H12.3084C11.95 2.95008 11.6667 2.65841 11.6667 2.30841C11.6667 1.95841 11.95 1.66675 12.3084 1.66675H14.2334C16.4917 1.66675 18.3334 3.50841 18.3334 5.76675V7.69175C18.3334 8.05008 18.05 8.33341 17.6917 8.33341Z"
          fill="#A3AED0"
        />
        <path
          d="M14.2333 18.3333H13.075C12.725 18.3333 12.4333 18.05 12.4333 17.6916C12.4333 17.3416 12.7167 17.05 13.075 17.05H14.2333C15.7917 17.05 17.05 15.7833 17.05 14.2333V13.0833C17.05 12.7333 17.3333 12.4417 17.6917 12.4417C18.0417 12.4417 18.3333 12.725 18.3333 13.0833V14.2333C18.3333 16.4917 16.4917 18.3333 14.2333 18.3333Z"
          fill="#A3AED0"
        />
        <path
          d="M7.69169 18.3334H5.76669C3.50835 18.3334 1.66669 16.4917 1.66669 14.2334V12.3084C1.66669 11.9501 1.95002 11.6667 2.30835 11.6667C2.66669 11.6667 2.95002 11.9501 2.95002 12.3084V14.2334C2.95002 15.7917 4.21669 17.0501 5.76669 17.0501H7.69169C8.04169 17.0501 8.33335 17.3334 8.33335 17.6917C8.33335 18.0501 8.05002 18.3334 7.69169 18.3334Z"
          fill="#A3AED0"
        />
        <path
          d="M15.3833 9.3584H14.25H5.74998H4.61664C4.25831 9.3584 3.97498 9.65007 3.97498 10.0001C3.97498 10.3501 4.25831 10.6417 4.61664 10.6417H5.74998H14.25H15.3833C15.7416 10.6417 16.025 10.3501 16.025 10.0001C16.025 9.65007 15.7416 9.3584 15.3833 9.3584Z"
          fill="#A3AED0"
        />
        <path
          d="M5.75 11.6167V11.8917C5.75 13.275 6.86667 14.3917 8.25 14.3917H11.75C13.1333 14.3917 14.25 13.275 14.25 11.8917V11.6167C14.25 11.5167 14.175 11.4417 14.075 11.4417H5.925C5.825 11.4417 5.75 11.5167 5.75 11.6167Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.4"
          d="M5.75 8.3834V8.1084C5.75 6.72506 6.86667 5.6084 8.25 5.6084H11.75C13.1333 5.6084 14.25 6.72506 14.25 8.1084V8.3834C14.25 8.4834 14.175 8.5584 14.075 8.5584H5.925C5.825 8.5584 5.75 8.4834 5.75 8.3834Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    trxBarcode: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M1.66669 8.12508C1.32502 8.12508 1.04169 7.84175 1.04169 7.50008V5.41675C1.04169 3.00008 3.00835 1.04175 5.41669 1.04175H7.50002C7.84169 1.04175 8.12502 1.32508 8.12502 1.66675C8.12502 2.00841 7.84169 2.29175 7.50002 2.29175H5.41669C3.69169 2.29175 2.29169 3.69175 2.29169 5.41675V7.50008C2.29169 7.84175 2.00835 8.12508 1.66669 8.12508Z"
          fill="#A3AED0"
        />
        <path
          d="M18.3333 8.12508C17.9917 8.12508 17.7083 7.84175 17.7083 7.50008V5.41675C17.7083 3.69175 16.3083 2.29175 14.5833 2.29175H12.5C12.1583 2.29175 11.875 2.00841 11.875 1.66675C11.875 1.32508 12.1583 1.04175 12.5 1.04175H14.5833C16.9917 1.04175 18.9583 3.00008 18.9583 5.41675V7.50008C18.9583 7.84175 18.675 8.12508 18.3333 8.12508Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.4"
          d="M14.5833 18.9583H13.3333C12.9916 18.9583 12.7083 18.6749 12.7083 18.3333C12.7083 17.9916 12.9916 17.7083 13.3333 17.7083H14.5833C16.3083 17.7083 17.7083 16.3083 17.7083 14.5833V13.3333C17.7083 12.9916 17.9916 12.7083 18.3333 12.7083C18.675 12.7083 18.9583 12.9916 18.9583 13.3333V14.5833C18.9583 16.9999 16.9916 18.9583 14.5833 18.9583Z"
          fill="#A3AED0"
        />
        <path
          d="M7.50002 18.9583H5.41669C3.00835 18.9583 1.04169 17 1.04169 14.5833V12.5C1.04169 12.1583 1.32502 11.875 1.66669 11.875C2.00835 11.875 2.29169 12.1583 2.29169 12.5V14.5833C2.29169 16.3083 3.69169 17.7083 5.41669 17.7083H7.50002C7.84169 17.7083 8.12502 17.9917 8.12502 18.3333C8.12502 18.675 7.84169 18.9583 7.50002 18.9583Z"
          fill="#A3AED0"
        />
        <path
          d="M7.5 4.375H5.83333C4.88333 4.375 4.375 4.875 4.375 5.83333V7.5C4.375 8.45833 4.88333 8.95833 5.83333 8.95833H7.5C8.45 8.95833 8.95833 8.45833 8.95833 7.5V5.83333C8.95833 4.875 8.45 4.375 7.5 4.375Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.4"
          d="M14.1667 4.375H12.5C11.55 4.375 11.0417 4.875 11.0417 5.83333V7.5C11.0417 8.45833 11.55 8.95833 12.5 8.95833H14.1667C15.1167 8.95833 15.625 8.45833 15.625 7.5V5.83333C15.625 4.875 15.1167 4.375 14.1667 4.375Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.4"
          d="M7.5 11.0417H5.83333C4.88333 11.0417 4.375 11.5417 4.375 12.5001V14.1667C4.375 15.1251 4.88333 15.6251 5.83333 15.6251H7.5C8.45 15.6251 8.95833 15.1251 8.95833 14.1667V12.5001C8.95833 11.5417 8.45 11.0417 7.5 11.0417Z"
          fill="#A3AED0"
        />
        <path
          d="M14.1667 11.0417H12.5C11.55 11.0417 11.0417 11.5417 11.0417 12.5001V14.1667C11.0417 15.1251 11.55 15.6251 12.5 15.6251H14.1667C15.1167 15.6251 15.625 15.1251 15.625 14.1667V12.5001C15.625 11.5417 15.1167 11.0417 14.1667 11.0417Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    topupMemberViaMerchant: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M17.5416 9.75826V14.7499C17.5416 17.0499 15.675 18.9166 13.375 18.9166H6.09165C5.89999 18.9166 5.70833 18.8999 5.52499 18.8749C5.83333 18.7416 6.10833 18.5582 6.35833 18.3332C6.39166 18.3082 6.41664 18.2833 6.44164 18.2499C6.66664 18.0583 6.85831 17.8166 7.01664 17.5499C7.32498 17.0499 7.49999 16.4582 7.49999 15.8332C7.49999 14.9582 7.15832 14.1666 6.60832 13.5666C6.52498 13.4749 6.4333 13.3832 6.3333 13.3082C5.74997 12.7999 4.99999 12.4999 4.16666 12.4999C3.57499 12.4999 3.01664 12.6583 2.5333 12.9333C2.37497 13.0166 2.22499 13.1166 2.08332 13.2332C2.02499 13.2749 1.97499 13.3249 1.92499 13.3749V9.8166C2.55832 10.4999 3.45833 10.8916 4.43333 10.8916C5.48333 10.8916 6.49165 10.3666 7.12498 9.5249C7.69165 10.3666 8.65832 10.8916 9.73332 10.8916C10.8 10.8916 11.75 10.3916 12.325 9.55822C12.9666 10.3832 13.9583 10.8916 14.9916 10.8916C16 10.8916 16.9166 10.4833 17.5416 9.75826Z"
          fill="#A3AED0"
        />
        <path
          d="M12.225 1.30835H7.22496L6.6083 7.44167C6.5583 8.00834 6.64161 8.54169 6.84995 9.02502C7.33328 10.1584 8.46663 10.8917 9.7333 10.8917C11.0166 10.8917 12.125 10.175 12.625 9.03337C12.775 8.67503 12.8666 8.25836 12.875 7.83336V7.67503L12.225 1.30835Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.6"
          d="M18.3666 7.15841L18.1333 4.84172C17.7833 2.32505 16.6416 1.30005 14.2 1.30005H11L11.6167 7.55005C11.625 7.63338 11.6333 7.72507 11.6333 7.8834C11.6833 8.31674 11.8166 8.71673 12.0166 9.07506C12.6166 10.1751 13.7833 10.8834 15 10.8834C16.1083 10.8834 17.1083 10.3917 17.7333 9.52504C18.225 8.86671 18.45 8.02507 18.3666 7.15841Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.6"
          d="M5.22502 1.30835C2.77502 1.30835 1.64165 2.33332 1.28332 4.87499L1.05835 7.16666C0.975017 8.05832 1.21669 8.92503 1.74169 9.60003C2.37502 10.425 3.35001 10.8917 4.43334 10.8917C5.65001 10.8917 6.81668 10.1833 7.40835 9.1C7.62501 8.725 7.76666 8.29165 7.80833 7.84165L8.45835 1.31669H5.22502V1.30835Z"
          fill="#A3AED0"
        />
        <path
          d="M6.60831 13.5667C6.52497 13.475 6.43329 13.3833 6.33329 13.3083C5.74996 12.8 4.99998 12.5 4.16665 12.5C3.57498 12.5 3.01663 12.6583 2.53329 12.9333C2.37496 13.0167 2.22498 13.1167 2.08331 13.2333C1.31665 13.8417 0.833313 14.7833 0.833313 15.8333C0.833313 16.4583 1.00833 17.05 1.31666 17.55C1.39166 17.6833 1.48333 17.8083 1.58333 17.925C1.6 17.95 1.62496 17.9667 1.64162 17.9917C2.24162 18.7084 3.14998 19.1667 4.16665 19.1667C5.00831 19.1667 5.77498 18.8583 6.35832 18.3333C6.39165 18.3083 6.41663 18.2834 6.44163 18.25C6.66663 18.0584 6.8583 17.8167 7.01663 17.55C7.32497 17.05 7.49998 16.4583 7.49998 15.8333C7.49998 14.9583 7.15831 14.1667 6.60831 13.5667ZM5.41665 16.4417H4.79165V17.0917C4.79165 17.4333 4.50831 17.7167 4.16665 17.7167C3.82498 17.7167 3.54165 17.4333 3.54165 17.0917V16.4417H2.91665C2.57498 16.4417 2.29165 16.1583 2.29165 15.8167C2.29165 15.475 2.57498 15.1917 2.91665 15.1917H3.54165V14.6C3.54165 14.2583 3.82498 13.975 4.16665 13.975C4.50831 13.975 4.79165 14.2583 4.79165 14.6V15.1917H5.41665C5.75831 15.1917 6.04165 15.475 6.04165 15.8167C6.04165 16.1583 5.75831 16.4417 5.41665 16.4417Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    topupUserManual: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M18.3334 6.47492V7.49992H1.66669V6.28325C1.66669 4.37492 3.21669 2.83325 5.12502 2.83325H13.3334V4.97492C13.3334 6.03325 13.9667 6.66659 15.025 6.66659H17.475C17.8084 6.66659 18.0917 6.60825 18.3334 6.47492Z"
          fill="#A3AED0"
        />
        <path
          d="M1.66669 7.5V13.7167C1.66669 15.625 3.21669 17.1667 5.12502 17.1667H14.875C16.7834 17.1667 18.3334 15.625 18.3334 13.7167V7.5H1.66669ZM6.66669 14.375H5.00002C4.65835 14.375 4.37502 14.0917 4.37502 13.75C4.37502 13.4083 4.65835 13.125 5.00002 13.125H6.66669C7.00835 13.125 7.29169 13.4083 7.29169 13.75C7.29169 14.0917 7.00835 14.375 6.66669 14.375ZM12.0834 14.375H8.75002C8.40835 14.375 8.12502 14.0917 8.12502 13.75C8.12502 13.4083 8.40835 13.125 8.75002 13.125H12.0834C12.425 13.125 12.7084 13.4083 12.7084 13.75C12.7084 14.0917 12.425 14.375 12.0834 14.375Z"
          fill="#A3AED0"
        />
        <path
          d="M17.475 0.833252H15.025C13.9666 0.833252 13.3333 1.46659 13.3333 2.52492V4.97492C13.3333 6.03325 13.9666 6.66659 15.025 6.66659H17.475C18.5333 6.66659 19.1666 6.03325 19.1666 4.97492V2.52492C19.1666 1.46659 18.5333 0.833252 17.475 0.833252ZM18.2583 4.10825C18.175 4.19159 18.05 4.24992 17.9166 4.25825H16.7416L16.75 5.41659C16.7416 5.55825 16.6916 5.67492 16.5916 5.77492C16.5083 5.85825 16.3833 5.91659 16.25 5.91659C15.975 5.91659 15.75 5.69159 15.75 5.41659V4.24992L14.5833 4.25825C14.3083 4.25825 14.0833 4.02492 14.0833 3.74992C14.0833 3.47492 14.3083 3.24992 14.5833 3.24992L15.75 3.25825V2.09159C15.75 1.81659 15.975 1.58325 16.25 1.58325C16.525 1.58325 16.75 1.81659 16.75 2.09159L16.7416 3.24992H17.9166C18.1916 3.24992 18.4166 3.47492 18.4166 3.74992C18.4083 3.89159 18.35 4.00825 18.2583 4.10825Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    pencairanUserManual: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.475 11.9416C16.875 11.2583 15.9834 10.8333 15 10.8333C14.0667 10.8333 13.2167 11.2166 12.6084 11.8416C12.025 12.4416 11.6667 13.2666 11.6667 14.1666C11.6667 14.7916 11.8417 15.3832 12.15 15.8832C12.3167 16.1666 12.5334 16.4249 12.7834 16.6416C13.3667 17.1749 14.1417 17.4999 15 17.4999C16.2167 17.4999 17.275 16.8499 17.85 15.8832C18.025 15.5999 18.1584 15.2749 18.2334 14.9416C18.3 14.6916 18.3334 14.4333 18.3334 14.1666C18.3334 13.3166 18.0083 12.5333 17.475 11.9416ZM16.25 14.7749H13.7584C13.4167 14.7749 13.1334 14.4916 13.1334 14.1499C13.1334 13.8082 13.4167 13.5249 13.7584 13.5249H16.25C16.5917 13.5249 16.875 13.8082 16.875 14.1499C16.875 14.4916 16.5917 14.7749 16.25 14.7749Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.4"
          d="M17.575 17.9167C17.575 18.1501 17.3917 18.3334 17.1584 18.3334H2.84165C2.60832 18.3334 2.42499 18.1501 2.42499 17.9167C2.42499 14.4667 5.82501 11.6667 10 11.6667C10.8583 11.6667 11.6917 11.7834 12.4584 12.0084C11.9667 12.5917 11.6667 13.3501 11.6667 14.1667C11.6667 14.7917 11.8417 15.3834 12.15 15.8834C12.3167 16.1667 12.5334 16.4251 12.7834 16.6417C13.3667 17.1751 14.1417 17.5001 15 17.5001C15.9333 17.5001 16.775 17.1167 17.375 16.5001C17.5083 16.9501 17.575 17.4251 17.575 17.9167Z"
          fill="#A3AED0"
        />
        <path
          d="M9.99998 10.0001C12.3012 10.0001 14.1666 8.1346 14.1666 5.83341C14.1666 3.53223 12.3012 1.66675 9.99998 1.66675C7.69879 1.66675 5.83331 3.53223 5.83331 5.83341C5.83331 8.1346 7.69879 10.0001 9.99998 10.0001Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    dataLogVa: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M5.60835 16.4165C6.29169 15.6832 7.33335 15.7415 7.93335 16.5415L8.77502 17.6665C9.45002 18.5582 10.5417 18.5582 11.2167 17.6665L12.0584 16.5415C12.6584 15.7415 13.7 15.6832 14.3834 16.4165C15.8667 17.9998 17.075 17.4748 17.075 15.2582V5.8665C17.0834 2.50817 16.3 1.6665 13.15 1.6665H6.85002C3.70002 1.6665 2.91669 2.50817 2.91669 5.8665V15.2498C2.91669 17.4748 4.13335 17.9915 5.60835 16.4165Z"
          fill="#A3AED0"
        />
        <path
          d="M13.3334 6.4585H6.66669C6.32502 6.4585 6.04169 6.17516 6.04169 5.8335C6.04169 5.49183 6.32502 5.2085 6.66669 5.2085H13.3334C13.675 5.2085 13.9584 5.49183 13.9584 5.8335C13.9584 6.17516 13.675 6.4585 13.3334 6.4585Z"
          fill="#A3AED0"
        />
        <path
          d="M12.5 9.7915H7.5C7.15833 9.7915 6.875 9.50817 6.875 9.1665C6.875 8.82484 7.15833 8.5415 7.5 8.5415H12.5C12.8417 8.5415 13.125 8.82484 13.125 9.1665C13.125 9.50817 12.8417 9.7915 12.5 9.7915Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    tagihan: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.3333 4.99991V7.01657C18.3333 8.33324 17.5 9.16657 16.1833 9.16657H13.3333V3.34157C13.3333 2.41657 14.0916 1.65824 15.0166 1.66657C15.925 1.67491 16.7583 2.04157 17.3583 2.64157C17.9583 3.24991 18.3333 4.08324 18.3333 4.99991Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.4"
          d="M1.66669 5.83342V17.5001C1.66669 18.1917 2.45002 18.5834 3.00002 18.1667L4.42502 17.1001C4.75835 16.8501 5.22502 16.8834 5.52502 17.1834L6.90835 18.5751C7.23335 18.9001 7.76669 18.9001 8.09169 18.5751L9.49169 17.1751C9.78335 16.8834 10.25 16.8501 10.575 17.1001L12 18.1667C12.55 18.5751 13.3334 18.1834 13.3334 17.5001V3.33341C13.3334 2.41675 14.0834 1.66675 15 1.66675H5.83335H5.00002C2.50002 1.66675 1.66669 3.15841 1.66669 5.00008V5.83342Z"
          fill="#A3AED0"
        />
        <path
          d="M10 10.2168H7.5C7.15833 10.2168 6.875 10.5001 6.875 10.8418C6.875 11.1835 7.15833 11.4668 7.5 11.4668H10C10.3417 11.4668 10.625 11.1835 10.625 10.8418C10.625 10.5001 10.3417 10.2168 10 10.2168Z"
          fill="#A3AED0"
        />
        <path
          d="M7.5 8.1333H10C10.3417 8.1333 10.625 7.84997 10.625 7.5083C10.625 7.16663 10.3417 6.8833 10 6.8833H7.5C7.15833 6.8833 6.875 7.16663 6.875 7.5083C6.875 7.84997 7.15833 8.1333 7.5 8.1333Z"
          fill="#A3AED0"
        />
        <path
          d="M4.975 6.67505C4.50833 6.67505 4.14166 7.05005 4.14166 7.50838C4.14166 7.96672 4.51666 8.34172 4.975 8.34172C5.43333 8.34172 5.80833 7.96672 5.80833 7.50838C5.80833 7.05005 5.43333 6.67505 4.975 6.67505Z"
          fill="#A3AED0"
        />
        <path
          d="M4.975 10.0083C4.50833 10.0083 4.14166 10.3833 4.14166 10.8416C4.14166 11.3 4.51666 11.675 4.975 11.675C5.43333 11.675 5.80833 11.3 5.80833 10.8416C5.80833 10.3833 5.43333 10.0083 4.975 10.0083Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    digitalProduk: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.44168 12.5084L7.58334 11.6501L8.40834 10.8251C8.65001 10.5834 8.65001 10.1834 8.40834 9.94175C8.16668 9.70008 7.76667 9.70008 7.52501 9.94175L6.70001 10.7667L5.90001 9.96675C5.65834 9.72508 5.25834 9.72508 5.01667 9.96675C4.77501 10.2084 4.77501 10.6084 5.01667 10.8501L5.81667 11.6501L4.99167 12.4751C4.75001 12.7167 4.75001 13.1167 4.99167 13.3584C5.11667 13.4834 5.27501 13.5417 5.43334 13.5417C5.59167 13.5417 5.75001 13.4834 5.87501 13.3584L6.70001 12.5334L7.55834 13.3917C7.68334 13.5167 7.84168 13.5751 8.00001 13.5751C8.15834 13.5751 8.31668 13.5167 8.44168 13.3917C8.68334 13.1501 8.68334 12.7501 8.44168 12.5084Z"
          fill="#A3AED0"
        />
        <path
          d="M11.2833 12.4999C10.825 12.4999 10.4417 12.1249 10.4417 11.6666C10.4417 11.2083 10.8083 10.8333 11.2667 10.8333H11.2833C11.7417 10.8333 12.1167 11.2083 12.1167 11.6666C12.1167 12.1249 11.75 12.4999 11.2833 12.4999Z"
          fill="#A3AED0"
        />
        <path
          d="M14.5666 12.4999C14.1083 12.4999 13.725 12.1249 13.725 11.6666C13.725 11.2083 14.0916 10.8333 14.55 10.8333H14.5666C15.025 10.8333 15.4 11.2083 15.4 11.6666C15.4 12.1249 15.0333 12.4999 14.5666 12.4999Z"
          fill="#A3AED0"
        />
        <path
          d="M12.9166 14.1416C12.4583 14.1416 12.0833 13.7749 12.0833 13.3166V13.2999C12.0833 12.8416 12.4583 12.4666 12.9166 12.4666C13.375 12.4666 13.75 12.8416 13.75 13.2999C13.75 13.7582 13.3833 14.1416 12.9166 14.1416Z"
          fill="#A3AED0"
        />
        <path
          d="M12.9166 10.8583C12.4583 10.8583 12.0833 10.4917 12.0833 10.0333V10.0167C12.0833 9.55835 12.4583 9.18335 12.9166 9.18335C13.375 9.18335 13.75 9.55835 13.75 10.0167C13.75 10.475 13.3833 10.8583 12.9166 10.8583Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.4"
          d="M18.3334 9.22494V13.8749C18.3334 16.3333 16.3334 18.3333 13.875 18.3333H6.12502C3.66669 18.3333 1.66669 16.3333 1.66669 13.8749V9.22494C1.66669 6.7666 3.66669 4.7666 6.12502 4.7666H13.875C16.3334 4.7666 18.3334 6.7666 18.3334 9.22494Z"
          fill="#A3AED0"
        />
        <path
          d="M11.3667 2.25841L11.3584 3.04175C11.35 3.77508 10.7417 4.38341 10 4.38341C9.87503 4.38341 9.80003 4.46675 9.80003 4.57508C9.80003 4.68341 9.88336 4.76675 9.9917 4.76675H8.65003C8.6417 4.70841 8.63336 4.64175 8.63336 4.57508C8.63336 3.82508 9.2417 3.21675 9.98336 3.21675C10.1084 3.21675 10.1917 3.13341 10.1917 3.02508L10.2 2.24175C10.2084 1.92508 10.4667 1.66675 10.7834 1.66675H10.7917C11.1167 1.66675 11.3667 1.93341 11.3667 2.25841Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    tokoOnline: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M17.8083 9.49163V14.4833C17.8083 16.7833 15.9417 18.65 13.6417 18.65H6.35832C4.05832 18.65 2.19165 16.7833 2.19165 14.4833V9.54997C2.82498 10.2333 3.72498 10.625 4.69998 10.625C5.74998 10.625 6.75832 10.1 7.39165 9.2583C7.95832 10.1 8.92498 10.625 9.99998 10.625C11.0667 10.625 12.0167 10.125 12.5917 9.29163C13.2333 10.1166 14.225 10.625 15.2583 10.625C16.2666 10.625 17.1833 10.2166 17.8083 9.49163Z"
          fill="#A3AED0"
        />
        <path
          d="M12.4917 1.04175H7.49169L6.87502 7.17508C6.82502 7.74175 6.90835 8.27508 7.11669 8.75841C7.60002 9.89175 8.73335 10.6251 10 10.6251C11.2834 10.6251 12.3917 9.90841 12.8917 8.76675C13.0417 8.40841 13.1334 7.99175 13.1417 7.56675V7.40841L12.4917 1.04175Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.6"
          d="M18.6334 6.89175L18.3917 4.58341C18.0417 2.06675 16.9 1.04175 14.4584 1.04175H11.2584L11.875 7.29175C11.8834 7.37508 11.8917 7.46675 11.8917 7.62508C11.9417 8.05841 12.075 8.45841 12.275 8.81675C12.875 9.91675 14.0417 10.6251 15.2584 10.6251C16.3667 10.6251 17.3667 10.1334 17.9917 9.26675C18.4917 8.60008 18.7167 7.75841 18.6334 6.89175Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.6"
          d="M5.49168 1.04175C3.04168 1.04175 1.90835 2.06675 1.55001 4.60841L1.32501 6.90008C1.24168 7.79175 1.48335 8.65841 2.00835 9.33341C2.64168 10.1584 3.61668 10.6251 4.70001 10.6251C5.91668 10.6251 7.08335 9.91675 7.67501 8.83342C7.89168 8.45842 8.03335 8.02508 8.07501 7.57508L8.72501 1.05008H5.49168V1.04175Z"
          fill="#A3AED0"
        />
        <path
          d="M9.45831 13.8834C8.39998 13.9917 7.59998 14.8917 7.59998 15.9584V18.65H12.3916V16.25C12.4 14.5084 11.375 13.6834 9.45831 13.8834Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    donasi: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.4375 13.75C13.4375 13.2292 13.125 11.4375 11.875 10.9375C10.625 10.4375 8.4375 9.6875 7.5 9.375H5M5 9.375H1.25V16.875H5V15.3125M5 9.375V15.3125M5 15.3125L11.5625 17.1875L17.5 15.3125C17.6042 14.7917 17.5 13.75 16.25 13.75C15 13.75 12.1875 13.75 10.9375 13.75L8.75 12.8125"
          stroke="#A3AED0"
          strokeWidth="2"
        />
        <circle
          cx="15.3125"
          cy="5.3125"
          r="2.4375"
          stroke="#C8CEE3"
          strokeWidth="2"
        />
      </svg>
    ),
    manajemenKartu: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M18.3334 6.47516V7.50016H1.66669V6.2835C1.66669 4.37516 3.21669 2.8335 5.12502 2.8335H13.3334V4.97516C13.3334 6.0335 13.9667 6.66683 15.025 6.66683H17.475C17.8084 6.66683 18.0917 6.6085 18.3334 6.47516Z"
          fill="#A3AED0"
        />
        <path
          d="M1.66669 7.5V13.7167C1.66669 15.625 3.21669 17.1667 5.12502 17.1667H14.875C16.7834 17.1667 18.3334 15.625 18.3334 13.7167V7.5H1.66669ZM6.66669 14.375H5.00002C4.65835 14.375 4.37502 14.0917 4.37502 13.75C4.37502 13.4083 4.65835 13.125 5.00002 13.125H6.66669C7.00835 13.125 7.29169 13.4083 7.29169 13.75C7.29169 14.0917 7.00835 14.375 6.66669 14.375ZM12.0834 14.375H8.75002C8.40835 14.375 8.12502 14.0917 8.12502 13.75C8.12502 13.4083 8.40835 13.125 8.75002 13.125H12.0834C12.425 13.125 12.7084 13.4083 12.7084 13.75C12.7084 14.0917 12.425 14.375 12.0834 14.375Z"
          fill="#A3AED0"
        />
        <path
          d="M17.475 0.833496H15.025C13.9666 0.833496 13.3333 1.46683 13.3333 2.52516V4.97516C13.3333 6.0335 13.9666 6.66683 15.025 6.66683H17.475C18.5333 6.66683 19.1666 6.0335 19.1666 4.97516V2.52516C19.1666 1.46683 18.5333 0.833496 17.475 0.833496ZM18.025 2.87516L16.05 5.1835C15.975 5.27516 15.8583 5.3335 15.7416 5.3335C15.7333 5.3335 15.7333 5.3335 15.725 5.3335C15.6083 5.3335 15.5 5.29183 15.4166 5.2085L14.5 4.3085C14.3333 4.14183 14.3333 3.86683 14.5 3.70016C14.6666 3.5335 14.9416 3.52516 15.1083 3.70016L15.6916 4.27516L17.3583 2.32516C17.5166 2.14183 17.7833 2.12516 17.9666 2.27516C18.1583 2.42516 18.175 2.70016 18.025 2.87516Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    manajemenIntegrasi: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M18.3084 5.3415V10.7582H1.66669V5.3415C1.66669 3.3165 3.31669 1.6665 5.34169 1.6665H14.6334C16.6584 1.6665 18.3084 3.3165 18.3084 5.3415Z"
          fill="#A3AED0"
        />
        <path
          d="M1.66669 10.7666V10.9333C1.66669 12.9666 3.31669 14.6083 5.34169 14.6083H8.54169C9.00002 14.6083 9.37502 14.9833 9.37502 15.4416V16.2499C9.37502 16.7083 9.00002 17.0833 8.54169 17.0833H6.52502C6.18335 17.0833 5.90002 17.3666 5.90002 17.7083C5.90002 18.0499 6.17502 18.3333 6.52502 18.3333H13.4834C13.825 18.3333 14.1084 18.0499 14.1084 17.7083C14.1084 17.3666 13.825 17.0833 13.4834 17.0833H11.4667C11.0084 17.0833 10.6334 16.7083 10.6334 16.2499V15.4416C10.6334 14.9833 11.0084 14.6083 11.4667 14.6083H14.6417C16.675 14.6083 18.3167 12.9583 18.3167 10.9333V10.7666H1.66669Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    manajemenAkses: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M16.4916 3.5166C14.025 1.05827 10.025 1.05827 7.57498 3.5166C5.84998 5.22493 5.33331 7.68327 5.99998 9.84993L2.08331 13.7666C1.80831 14.0499 1.61665 14.6083 1.67498 15.0083L1.92498 16.8249C2.01665 17.4249 2.57498 17.9916 3.17498 18.0749L4.99165 18.3249C5.39165 18.3833 5.94998 18.1999 6.23331 17.9083L6.91665 17.2249C7.08331 17.0666 7.08331 16.7999 6.91665 16.6333L5.29998 15.0166C5.05831 14.7749 5.05831 14.3749 5.29998 14.1333C5.54165 13.8916 5.94165 13.8916 6.18331 14.1333L7.80831 15.7583C7.96665 15.9166 8.23331 15.9166 8.39165 15.7583L10.1583 13.9999C12.3166 14.6749 14.775 14.1499 16.4916 12.4416C18.95 9.98327 18.95 5.97494 16.4916 3.5166ZM12.0833 9.99994C10.9333 9.99994 9.99998 9.0666 9.99998 7.9166C9.99998 6.7666 10.9333 5.83327 12.0833 5.83327C13.2333 5.83327 14.1666 6.7666 14.1666 7.9166C14.1666 9.0666 13.2333 9.99994 12.0833 9.99994Z"
          fill="#A3AED0"
        />
        <path
          d="M12.0833 10.0002C13.2339 10.0002 14.1667 9.06742 14.1667 7.91683C14.1667 6.76624 13.2339 5.8335 12.0833 5.8335C10.9327 5.8335 10 6.76624 10 7.91683C10 9.06742 10.9327 10.0002 12.0833 10.0002Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    propertiesMenu: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M14 1.6665H5.17499C3.24165 1.6665 1.67499 3.23317 1.67499 5.15817V10.3082V10.4665C1.67499 12.3998 3.24165 13.9582 5.16665 13.9582H8.16665C8.62499 13.9582 8.99999 14.3332 8.99999 14.7915V15.4748C8.99999 15.9332 8.62499 16.3082 8.16665 16.3082H6.29165C5.96665 16.3082 5.69999 16.5748 5.69999 16.8998C5.69999 17.2248 5.96665 17.4915 6.29165 17.4915H12.9C13.225 17.4915 13.4917 17.2248 13.4917 16.8998C13.4917 16.5748 13.225 16.3082 12.9 16.3082H11.025C10.5667 16.3082 10.1917 15.9332 10.1917 15.4748V14.7915C10.1917 14.3332 10.5667 13.9582 11.025 13.9582H14C15.9333 13.9582 17.4917 12.3915 17.4917 10.4665V10.3082V5.15817C17.4917 3.23317 15.925 1.6665 14 1.6665Z"
          fill="#A3AED0"
        />
        <path
          d="M16.3666 7.9165H11.9583C10.5166 7.9165 9.99164 8.4415 9.99164 9.89984V16.3415C9.99164 17.8082 10.5083 18.3248 11.9583 18.3248H16.3666C17.8083 18.3248 18.3333 17.7998 18.3333 16.3415V9.89984C18.3333 8.43317 17.8166 7.9165 16.3666 7.9165ZM14.1666 16.9332C13.6583 16.9332 13.25 16.5248 13.25 16.0165C13.25 15.5082 13.6583 15.0998 14.1666 15.0998C14.675 15.0998 15.0833 15.5082 15.0833 16.0165C15.0833 16.5248 14.675 16.9332 14.1666 16.9332Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.4"
          d="M15.0833 16.0168C15.0833 16.5251 14.675 16.9334 14.1667 16.9334C13.6583 16.9334 13.25 16.5251 13.25 16.0168C13.25 15.5084 13.6583 15.1001 14.1667 15.1001C14.675 15.1001 15.0833 15.5168 15.0833 16.0168Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    subMenu: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M15.625 17.5002C15.625 17.8418 15.3417 18.1252 15 18.1252H11.6667C11.625 18.1252 11.5917 18.1085 11.55 18.1001C11.3083 18.7251 10.7083 19.1668 10 19.1668C9.29167 19.1668 8.69168 18.7251 8.45001 18.1001C8.40835 18.1085 8.375 18.1252 8.33333 18.1252H5C4.65833 18.1252 4.375 17.8418 4.375 17.5002C4.375 17.1585 4.65833 16.8752 5 16.8752H8.33333C8.375 16.8752 8.40835 16.8919 8.45001 16.9002C8.61668 16.4669 8.96669 16.1168 9.40002 15.9502C9.39169 15.9085 9.375 15.8752 9.375 15.8335V13.3335H10.625V15.8335C10.625 15.8752 10.6083 15.9085 10.6 15.9502C11.0333 16.1168 11.3833 16.4669 11.55 16.9002C11.5917 16.8919 11.625 16.8752 11.6667 16.8752H15C15.3417 16.8752 15.625 17.1585 15.625 17.5002Z"
          fill="#A3AED0"
        />
        <path
          d="M15.8334 6.91649V10.4165C15.8334 12.7498 15.25 13.3332 12.9167 13.3332H7.08335C4.75002 13.3332 4.16669 12.7498 4.16669 10.4165V4.58317C4.16669 2.24984 4.75002 1.6665 7.08335 1.6665H7.95834C8.83334 1.6665 9.025 1.92486 9.35834 2.36653L10.2334 3.53316C10.4584 3.82483 10.5834 3.99983 11.1667 3.99983H12.9167C15.25 3.99983 15.8334 4.58316 15.8334 6.91649Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    konfigNotifikasiTemplate: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M17 13.6085C16.7667 14.2335 16.275 14.7085 15.6334 14.9252C14.7334 15.2252 13.8084 15.4502 12.875 15.6085C12.7834 15.6252 12.6917 15.6418 12.6 15.6502C12.45 15.6752 12.3 15.6918 12.15 15.7085C11.9667 15.7335 11.775 15.7502 11.5834 15.7668C11.0584 15.8085 10.5417 15.8335 10.0167 15.8335C9.48336 15.8335 8.95003 15.8085 8.42503 15.7585C8.20003 15.7418 7.98336 15.7168 7.76669 15.6835C7.64169 15.6668 7.51669 15.6502 7.40003 15.6335C7.30836 15.6168 7.21669 15.6085 7.12503 15.5918C6.20003 15.4418 5.28336 15.2168 4.39169 14.9168C3.72503 14.6918 3.21669 14.2168 2.99169 13.6085C2.76669 13.0085 2.85003 12.3085 3.20836 11.7085L4.15003 10.1418C4.35003 9.80016 4.53336 9.14183 4.53336 8.74183V7.19183C4.53336 4.16683 6.99169 1.7085 10.0167 1.7085C13.0334 1.7085 15.4917 4.16683 15.4917 7.19183V8.74183C15.4917 9.14183 15.675 9.80016 15.8834 10.1418L16.825 11.7085C17.1667 12.2918 17.2334 12.9752 17 13.6085Z"
          fill="#A3AED0"
        />
        <path
          d="M9.99997 8.9667C9.64997 8.9667 9.36664 8.68337 9.36664 8.33337V5.75003C9.36664 5.40003 9.64997 5.1167 9.99997 5.1167C10.35 5.1167 10.6333 5.40003 10.6333 5.75003V8.33337C10.625 8.68337 10.3416 8.9667 9.99997 8.9667Z"
          fill="#A3AED0"
        />
        <path
          d="M12.3584 16.6748C12.0084 17.6415 11.0834 18.3332 10 18.3332C9.34169 18.3332 8.69169 18.0665 8.23336 17.5915C7.96669 17.3415 7.76669 17.0082 7.65002 16.6665C7.75836 16.6832 7.86669 16.6915 7.98336 16.7082C8.17502 16.7332 8.37502 16.7582 8.57502 16.7748C9.05002 16.8165 9.53336 16.8415 10.0167 16.8415C10.4917 16.8415 10.9667 16.8165 11.4334 16.7748C11.6084 16.7582 11.7834 16.7498 11.95 16.7248C12.0834 16.7082 12.2167 16.6915 12.3584 16.6748Z"
          fill="#A3AED0"
        />
      </svg>
    ),
  },

  sideBarSuper: {
    monitor: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          d="M8.42501 3.33334C8.35834 3.58334 8.33335 3.85834 8.33335 4.16667V6.66667H4.16669V5C4.16669 4.08334 4.91669 3.33334 5.83335 3.33334H8.42501Z"
          fill="#A3AED0"
        />
        <path
          d="M8.33335 15.8333V6.66666H4.16669C2.50002 6.66666 1.66669 7.5 1.66669 9.16666V15.8333C1.66669 17.5 2.50002 18.3333 4.16669 18.3333H10.8334C9.16669 18.3333 8.33335 17.5 8.33335 15.8333ZM5.62502 14.1667C5.62502 14.5083 5.34169 14.7917 5.00002 14.7917C4.65835 14.7917 4.37502 14.5083 4.37502 14.1667V10.8333C4.37502 10.4917 4.65835 10.2083 5.00002 10.2083C5.34169 10.2083 5.62502 10.4917 5.62502 10.8333V14.1667Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.4"
          d="M11.6666 18.3333H10.8333C9.16665 18.3333 8.33331 17.5 8.33331 15.8333V4.16666C8.33331 2.5 9.16665 1.66666 10.8333 1.66666H15.8333C17.5 1.66666 18.3333 2.5 18.3333 4.16666V15.8333C18.3333 17.5 17.5 18.3333 15.8333 18.3333H15"
          fill="#A3AED0"
        />
        <path
          d="M15 15V18.3333H11.6667V15C11.6667 14.5417 12.0417 14.1667 12.5 14.1667H14.1667C14.625 14.1667 15 14.5417 15 15Z"
          fill="#A3AED0"
        />
        <path
          d="M11.6667 11.4583C11.325 11.4583 11.0417 11.175 11.0417 10.8333V6.66666C11.0417 6.325 11.325 6.04166 11.6667 6.04166C12.0084 6.04166 12.2917 6.325 12.2917 6.66666V10.8333C12.2917 11.175 12.0084 11.4583 11.6667 11.4583Z"
          fill="#A3AED0"
        />
        <path
          d="M15 11.4583C14.6583 11.4583 14.375 11.175 14.375 10.8333V6.66666C14.375 6.325 14.6583 6.04166 15 6.04166C15.3417 6.04166 15.625 6.325 15.625 6.66666V10.8333C15.625 11.175 15.3417 11.4583 15 11.4583Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    company: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.8333 3.45835V5H7.59999C6.25832 5 5.58333 5.67499 5.58333 7.01665V15H3.45834C2.26668 15 1.66666 14.4 1.66666 13.2083V3.45835C1.66666 2.26669 2.26668 1.66667 3.45834 1.66667H7.04167C8.23333 1.66667 8.8333 2.26669 8.8333 3.45835Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.4"
          d="M14.475 7.01665V16.3167C14.475 17.6583 13.8084 18.3333 12.4667 18.3333H7.6C6.25833 18.3333 5.58334 17.6583 5.58334 16.3167V7.01665C5.58334 5.67499 6.25833 5 7.6 5H12.4667C13.8084 5 14.475 5.67499 14.475 7.01665Z"
          fill="#A3AED0"
        />
        <path
          d="M18.3333 3.45835V13.2083C18.3333 14.4 17.7333 15 16.5416 15H14.475V7.01665C14.475 5.67499 13.8084 5 12.4667 5H11.1667V3.45835C11.1667 2.26669 11.7667 1.66667 12.9583 1.66667H16.5416C17.7333 1.66667 18.3333 2.26669 18.3333 3.45835Z"
          fill="#A3AED0"
        />
        <path
          d="M11.6667 9.79167H8.33334C7.99168 9.79167 7.70834 9.50833 7.70834 9.16667C7.70834 8.825 7.99168 8.54167 8.33334 8.54167H11.6667C12.0083 8.54167 12.2917 8.825 12.2917 9.16667C12.2917 9.50833 12.0083 9.79167 11.6667 9.79167Z"
          fill="#A3AED0"
        />
        <path
          d="M11.6667 12.2917H8.33334C7.99168 12.2917 7.70834 12.0083 7.70834 11.6667C7.70834 11.325 7.99168 11.0417 8.33334 11.0417H11.6667C12.0083 11.0417 12.2917 11.325 12.2917 11.6667C12.2917 12.0083 12.0083 12.2917 11.6667 12.2917Z"
          fill="#A3AED0"
        />
        <path
          d="M10.625 15.8333V18.3333H9.375V15.8333C9.375 15.4917 9.65833 15.2083 10 15.2083C10.3417 15.2083 10.625 15.4917 10.625 15.8333Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    balance: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.8"
          d="M10.75 2.1L10.725 2.15833L8.30834 7.76667H5.93334C5.36668 7.76667 4.83334 7.875 4.33334 8.09167L5.79168 4.60833L5.82501 4.53333L5.87501 4.4C5.90001 4.34167 5.91668 4.29167 5.94168 4.25C7.03334 1.725 8.26668 1.15 10.75 2.1Z"
          fill="#A3AED0"
        />
        <path
          d="M15.2417 7.93333C14.8667 7.825 14.475 7.76667 14.0667 7.76667H8.30832L10.725 2.15833L10.75 2.1C10.8667 2.14167 10.9917 2.2 11.1167 2.24167L12.9583 3.01667C13.9833 3.44167 14.7 3.88333 15.1417 4.41667C15.2167 4.51667 15.2833 4.60833 15.35 4.71667C15.425 4.83333 15.4833 4.95 15.5167 5.075C15.55 5.15 15.575 5.21667 15.5917 5.29167C15.8083 6 15.675 6.85833 15.2417 7.93333Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.4"
          d="M18.1333 11.8333V13.4583C18.1333 13.625 18.125 13.7917 18.1167 13.95C17.9583 16.8667 16.3333 18.3333 13.25 18.3333H6.75C6.54167 18.3333 6.35 18.3167 6.15833 18.2917C3.50833 18.1167 2.09167 16.7 1.90834 14.05C1.88334 13.85 1.86667 13.6583 1.86667 13.4583V11.8333C1.86667 10.1583 2.88334 8.71667 4.33334 8.09167C4.83334 7.875 5.36667 7.76667 5.93334 7.76667H14.0667C14.475 7.76667 14.8667 7.825 15.2417 7.93333C16.9083 8.44167 18.1333 9.99167 18.1333 11.8333Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.6"
          d="M5.79167 4.60833L4.33334 8.09167C2.88334 8.71667 1.86667 10.1583 1.86667 11.8333V9.39167C1.86667 7.025 3.55 5.05 5.79167 4.60833Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.6"
          d="M18.1333 9.39167V11.8333C18.1333 9.99167 16.9083 8.44167 15.2417 7.93333C15.675 6.85833 15.8083 6 15.5917 5.29167C15.575 5.21667 15.55 5.15 15.5167 5.075C17.075 5.88333 18.1333 7.525 18.1333 9.39167Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    bank: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.3333 15.8333V18.3333H1.66666V15.8333C1.66666 15.375 2.04166 15 2.49999 15H17.5C17.9583 15 18.3333 15.375 18.3333 15.8333Z"
          fill="#A3AED0"
          stroke="#A3AED0"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.6"
          d="M6.66668 9.16667H3.33334V15H6.66668V9.16667Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.4"
          d="M9.99999 9.16667H6.66666V15H9.99999V9.16667Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.6"
          d="M13.3333 9.16667H10V15H13.3333V9.16667Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.4"
          d="M16.6667 9.16667H13.3333V15H16.6667V9.16667Z"
          fill="#A3AED0"
        />
        <path
          d="M19.1667 18.9583H0.833344C0.491677 18.9583 0.208344 18.675 0.208344 18.3333C0.208344 17.9917 0.491677 17.7083 0.833344 17.7083H19.1667C19.5083 17.7083 19.7917 17.9917 19.7917 18.3333C19.7917 18.675 19.5083 18.9583 19.1667 18.9583Z"
          fill="#A3AED0"
        />
        <path
          d="M17.8083 4.79167L10.3083 1.79169C10.1417 1.72502 9.85833 1.72502 9.69166 1.79169L2.19166 4.79167C1.89999 4.90833 1.66666 5.24999 1.66666 5.56666V8.33333C1.66666 8.79167 2.04166 9.16667 2.49999 9.16667H17.5C17.9583 9.16667 18.3333 8.79167 18.3333 8.33333V5.56666C18.3333 5.24999 18.1 4.90833 17.8083 4.79167ZM9.99999 7.08333C9.30832 7.08333 8.74999 6.525 8.74999 5.83333C8.74999 5.14167 9.30832 4.58333 9.99999 4.58333C10.6917 4.58333 11.25 5.14167 11.25 5.83333C11.25 6.525 10.6917 7.08333 9.99999 7.08333Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    news: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.00001 10.8333C2.70001 10.8333 0.833344 12.6917 0.833344 15C0.833344 17.3083 2.70001 19.1667 5.00001 19.1667C7.30001 19.1667 9.16668 17.3 9.16668 15C9.16668 12.7 7.30834 10.8333 5.00001 10.8333ZM3.3 13.3H5.00001C5.31668 13.3 5.56667 13.5583 5.56667 13.8667C5.56667 14.175 5.30834 14.4333 5.00001 14.4333H3.3C2.98334 14.4333 2.73334 14.175 2.73334 13.8667C2.73334 13.5583 2.98334 13.3 3.3 13.3ZM6.70002 16.7H3.29169C2.97502 16.7 2.725 16.4417 2.725 16.1333C2.725 15.825 2.98335 15.5667 3.29169 15.5667H6.70002C7.01668 15.5667 7.26668 15.825 7.26668 16.1333C7.26668 16.4417 7.01668 16.7 6.70002 16.7Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.4"
          d="M14.1667 2.5H5.83332C3.33332 2.5 1.66666 3.75 1.66666 6.66667V9.28333C1.66666 9.89167 2.29167 10.275 2.85 10.0333C3.76667 9.63333 4.80831 9.48333 5.89998 9.65833C8.08331 10.0167 9.86665 11.7417 10.3083 13.9083C10.4333 14.5417 10.45 15.1583 10.3667 15.75C10.3 16.2417 10.7 16.675 11.1917 16.675H14.1667C16.6667 16.675 18.3333 15.425 18.3333 12.5083V6.675C18.3333 3.75 16.6667 2.5 14.1667 2.5Z"
          fill="#A3AED0"
        />
        <path
          d="M9.99998 9.89167C9.29998 9.89167 8.59165 9.675 8.04998 9.23334L5.44164 7.15C5.17498 6.93334 5.12498 6.54167 5.34165 6.275C5.55831 6.00834 5.94997 5.95834 6.21664 6.17501L8.82497 8.25834C9.45831 8.76667 10.5333 8.76667 11.1666 8.25834L13.775 6.17501C14.0416 5.95834 14.4416 6 14.65 6.275C14.8666 6.54167 14.825 6.94167 14.55 7.15L11.9416 9.23334C11.4083 9.675 10.7 9.89167 9.99998 9.89167Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    apiGateway: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M15.0333 11.2917C14.6833 11.6333 14.4833 12.125 14.5333 12.65C14.6083 13.55 15.4333 14.2083 16.3333 14.2083H17.9167V15.2C17.9167 16.925 16.5083 18.3333 14.7833 18.3333H5.21668C3.49168 18.3333 2.08334 16.925 2.08334 15.2V9.59167C2.08334 7.86667 3.49168 6.45833 5.21668 6.45833H14.7833C16.5083 6.45833 17.9167 7.86667 17.9167 9.59167V10.7917H16.2333C15.7667 10.7917 15.3417 10.975 15.0333 11.2917Z"
          fill="#A3AED0"
        />
        <path
          d="M12.375 3.29167V6.45833H5.21668C3.49168 6.45833 2.08334 7.86667 2.08334 9.59167V6.53335C2.08334 5.54168 2.69168 4.65831 3.61668 4.30831L10.2333 1.80831C11.2667 1.42498 12.375 2.18334 12.375 3.29167Z"
          fill="#A3AED0"
        />
        <path
          d="M18.8 11.6416V13.3584C18.8 13.8167 18.4333 14.1917 17.9667 14.2083H16.3333C15.4333 14.2083 14.6083 13.55 14.5333 12.65C14.4833 12.125 14.6833 11.6333 15.0333 11.2917C15.3417 10.975 15.7667 10.7917 16.2333 10.7917H17.9667C18.4333 10.8083 18.8 11.1833 18.8 11.6416Z"
          fill="#A3AED0"
        />
        <path
          d="M11.6667 10.625H5.83334C5.49168 10.625 5.20834 10.3417 5.20834 10C5.20834 9.65833 5.49168 9.375 5.83334 9.375H11.6667C12.0083 9.375 12.2917 9.65833 12.2917 10C12.2917 10.3417 12.0083 10.625 11.6667 10.625Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    device: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M13.3333 1.66667H4.99999C2.33332 1.66667 1.66666 2.33334 1.66666 5.00001V9.91669C1.69166 12.4417 2.39166 13.0833 4.99999 13.0833H6.87499V16.0417H4.125C3.78333 16.0417 3.5 16.325 3.5 16.6667C3.5 17.0083 3.78333 17.2917 4.125 17.2917H10.2083C10.0833 16.9667 10.025 16.5583 10.0166 16.0417H8.12499V13.0833H10.0166V9.91669V8.82503C10.0166 7.04169 10.6083 6.45 12.3917 6.45H15.9583C16.2167 6.45 16.4583 6.46665 16.6667 6.49165V5.00001C16.6667 2.33334 16 1.66667 13.3333 1.66667Z"
          fill="#A3AED0"
        />
        <path
          d="M16.6667 6.49165C16.4583 6.46665 16.2167 6.45 15.9583 6.45H12.3917C10.6083 6.45 10.0167 7.04169 10.0167 8.82503V16.0417C10.025 16.5583 10.0833 16.9667 10.2083 17.2917C10.5 18.05 11.1583 18.3333 12.3917 18.3333H15.9583C17.7417 18.3333 18.3333 17.7417 18.3333 15.9584V8.82503C18.3333 7.30003 17.9 6.64999 16.6667 6.49165ZM14.175 8.40836C14.9 8.40836 15.4833 8.99165 15.4833 9.71665C15.4833 10.4416 14.9 11.025 14.175 11.025C13.45 11.025 12.8667 10.4416 12.8667 9.71665C12.8667 8.99165 13.45 8.40836 14.175 8.40836ZM14.175 15.9584C13.1917 15.9584 12.3917 15.1583 12.3917 14.175C12.3917 13.7667 12.5333 13.3833 12.7667 13.0833C13.0917 12.6667 13.6 12.3917 14.175 12.3917C14.625 12.3917 15.0333 12.5584 15.3417 12.825C15.7167 13.1584 15.9583 13.6417 15.9583 14.175C15.9583 15.1583 15.1583 15.9584 14.175 15.9584Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.4"
          d="M15.9583 14.175C15.9583 15.1583 15.1583 15.9584 14.175 15.9584C13.1917 15.9584 12.3917 15.1583 12.3917 14.175C12.3917 13.7667 12.5333 13.3833 12.7667 13.0833C13.0917 12.6667 13.6 12.3917 14.175 12.3917C14.625 12.3917 15.0333 12.5583 15.3417 12.825C15.7167 13.1583 15.9583 13.6417 15.9583 14.175Z"
          fill="#A3AED0"
        />
        <path
          opacity="0.4"
          d="M14.175 11.025C14.8976 11.025 15.4833 10.4392 15.4833 9.71664C15.4833 8.99407 14.8976 8.40836 14.175 8.40836C13.4524 8.40836 12.8667 8.99407 12.8667 9.71664C12.8667 10.4392 13.4524 11.025 14.175 11.025Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    money: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M16.2333 10.7917H17.9167V9.59167C17.9167 7.86667 16.5083 6.45833 14.7833 6.45833H5.21667C3.49167 6.45833 2.08334 7.86667 2.08334 9.59167V12.6333C2.74168 12.0333 3.62501 11.6667 4.58334 11.6667C6.65834 11.6667 8.33334 13.35 8.33334 15.4167C8.33334 15.8583 8.25835 16.275 8.11668 16.6667C7.89168 17.325 7.47501 17.8917 6.95001 18.3333H14.7833C16.5083 18.3333 17.9167 16.925 17.9167 15.2V14.2083H16.3333C15.4333 14.2083 14.6083 13.55 14.5333 12.65C14.4833 12.125 14.6833 11.6333 15.0333 11.2917C15.3417 10.975 15.7667 10.7917 16.2333 10.7917Z"
          fill="#A3AED0"
        />
        <path
          d="M4.58334 11.6667C3.62501 11.6667 2.74168 12.0333 2.08334 12.6333C1.93334 12.7666 1.79168 12.9083 1.66668 13.0667C1.14168 13.7083 0.833344 14.525 0.833344 15.4167C0.833344 17.4917 2.51668 19.1667 4.58334 19.1667C5.48334 19.1667 6.30001 18.85 6.95001 18.3333C7.47501 17.8917 7.89168 17.325 8.11668 16.6667C8.25835 16.275 8.33334 15.8583 8.33334 15.4167C8.33334 13.35 6.65834 11.6667 4.58334 11.6667ZM6.83334 14.6917C6.83334 14.95 6.625 15.15 6.375 15.15C6.125 15.15 5.91668 14.95 5.91668 14.6917V14.6C5.91668 14.5 5.84168 14.4167 5.74168 14.4167H3.85835C3.95001 14.5917 3.93334 14.8083 3.78334 14.9583C3.7 15.0417 3.58334 15.0917 3.45834 15.0917C3.34167 15.0917 3.22501 15.0417 3.14168 14.9583L2.46668 14.2834C2.42502 14.2417 2.39167 14.1917 2.36667 14.1334C2.32501 14.025 2.32501 13.9 2.36667 13.7833C2.39167 13.7333 2.42502 13.675 2.46668 13.6333L3.14168 12.9667C3.31668 12.7833 3.60834 12.7833 3.78334 12.9667C3.93334 13.1083 3.95001 13.3333 3.85835 13.5H5.74168C6.34168 13.5 6.83334 13.9917 6.83334 14.6V14.6917ZM5.70835 18C5.58335 18 5.46668 17.9583 5.38335 17.8667C5.23335 17.725 5.21667 17.5 5.30834 17.3333H3.42501C2.82501 17.3333 2.33335 16.8417 2.33335 16.2333V16.1417C2.33335 15.8833 2.54168 15.6833 2.79168 15.6833C3.04168 15.6833 3.25001 15.8833 3.25001 16.1417V16.2333C3.25001 16.3333 3.32501 16.4167 3.42501 16.4167H5.30834C5.21667 16.2417 5.23335 16.025 5.38335 15.875C5.55835 15.7 5.85001 15.7 6.02501 15.875L6.7 16.55C6.74167 16.5917 6.77501 16.6417 6.80001 16.7C6.84168 16.8083 6.84168 16.9334 6.80001 17.05C6.77501 17.1 6.74167 17.1584 6.7 17.2L6.02501 17.8667C5.94167 17.9583 5.82502 18 5.70835 18Z"
          fill="#A3AED0"
        />
        <path
          d="M12.375 3.29166V6.45832H5.21667C3.49167 6.45832 2.08334 7.86666 2.08334 9.59166V6.53334C2.08334 5.54167 2.69167 4.6583 3.61667 4.3083L10.2333 1.8083C11.2667 1.42497 12.375 2.18333 12.375 3.29166Z"
          fill="#A3AED0"
        />
        <path
          d="M18.8 11.6416V13.3584C18.8 13.8167 18.4333 14.1917 17.9667 14.2083H16.3333C15.4333 14.2083 14.6083 13.55 14.5333 12.65C14.4833 12.125 14.6833 11.6333 15.0333 11.2917C15.3417 10.975 15.7667 10.7917 16.2333 10.7917H17.9667C18.4333 10.8083 18.8 11.1833 18.8 11.6416Z"
          fill="#A3AED0"
        />
        <path
          d="M11.6667 10.625H5.83334C5.49168 10.625 5.20834 10.3417 5.20834 10C5.20834 9.65833 5.49168 9.375 5.83334 9.375H11.6667C12.0083 9.375 12.2917 9.65833 12.2917 10C12.2917 10.3417 12.0083 10.625 11.6667 10.625Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    lms: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M9.99999 4.41668V17.775C9.85832 17.775 9.70832 17.75 9.59166 17.6833L9.55832 17.6667C7.95832 16.7917 5.16666 15.875 3.35832 15.6333L3.11666 15.6C2.31666 15.5 1.66666 14.75 1.66666 13.95V3.88335C1.66666 2.89168 2.47499 2.14168 3.46666 2.22501C5.21666 2.36668 7.86666 3.25002 9.34999 4.17502L9.55832 4.30001C9.68332 4.37501 9.84166 4.41668 9.99999 4.41668Z"
          fill="#A3AED0"
        />
        <path
          d="M18.3333 3.89168V13.95C18.3333 14.75 17.6833 15.5 16.8833 15.6L16.6083 15.6333C14.7917 15.875 11.9917 16.8 10.3917 17.6833C10.2833 17.75 10.15 17.775 10 17.775V4.41668C10.1583 4.41668 10.3167 4.37501 10.4417 4.30001L10.5833 4.20835C12.0667 3.27501 14.725 2.38335 16.475 2.23335H16.525C17.5167 2.15001 18.3333 2.89168 18.3333 3.89168Z"
          fill="#A3AED0"
        />
        <path
          d="M6.45834 7.70001H4.58334C4.24168 7.70001 3.95834 7.41668 3.95834 7.07501C3.95834 6.73335 4.24168 6.45001 4.58334 6.45001H6.45834C6.80001 6.45001 7.08334 6.73335 7.08334 7.07501C7.08334 7.41668 6.80001 7.70001 6.45834 7.70001Z"
          fill="#A3AED0"
        />
        <path
          d="M7.08334 10.2H4.58334C4.24168 10.2 3.95834 9.91668 3.95834 9.57501C3.95834 9.23335 4.24168 8.95001 4.58334 8.95001H7.08334C7.42501 8.95001 7.70834 9.23335 7.70834 9.57501C7.70834 9.91668 7.42501 10.2 7.08334 10.2Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    logDataTrx: (
      <svg
        className="nav-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M13.4917 1.66666H6.50832C3.47499 1.66666 1.66666 3.47499 1.66666 6.50832V13.4833C1.66666 16.525 3.47499 18.3333 6.50832 18.3333H13.4833C16.5167 18.3333 18.325 16.525 18.325 13.4917V6.50832C18.3333 3.47499 16.525 1.66666 13.4917 1.66666Z"
          fill="#A3AED0"
        />
        <path
          d="M13.6833 6.50832V13.4917C13.6833 14.025 13.25 14.4583 12.7167 14.4583C12.175 14.4583 11.7417 14.025 11.7417 13.4917V6.50832C11.7417 5.97499 12.175 5.54166 12.7167 5.54166C13.25 5.54166 13.6833 5.97499 13.6833 6.50832Z"
          fill="#A3AED0"
        />
        <path
          d="M8.25832 10.775V13.4917C8.25832 14.025 7.82498 14.4583 7.28332 14.4583C6.74998 14.4583 6.31665 14.025 6.31665 13.4917V10.775C6.31665 10.2417 6.74998 9.80832 7.28332 9.80832C7.82498 9.80832 8.25832 10.2417 8.25832 10.775Z"
          fill="#A3AED0"
        />
      </svg>
    ),
  },
};

export default Icons;
