import { configureStore } from "@reduxjs/toolkit";
import companyReducer from "./company/companySlice";
import userReducer from "./user/userSlice";
import balanceReducer from "./user/balanceSlice";
import configReducer from "./config/configSlice";
import tokenReducer from "./token/tokenSlice";
import templateReducer from "./template/templateSlice";
import menuReducer from "./menu/menuSlice";
import profileReducer from "./profile/profileSlice";
import otpReducer from "./otp/otpSlice";
import infoReducer from "./info/infoSlice";
import financialTypeReducer from "./financialType/financialType";
import filterDataReducer from "./filterData/filterDataSlice";

export const store = configureStore({
  reducer: {
    company: companyReducer,
    user: userReducer,
    balance: balanceReducer,
    config: configReducer,
    token: tokenReducer,
    template: templateReducer,
    menu: menuReducer,
    profile: profileReducer,
    otp: otpReducer,
    info: infoReducer,
    financialType: financialTypeReducer,
    filterData: filterDataReducer,
  },
});

export default store;
