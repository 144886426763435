import React from "react";
const chargePpob = React.lazy(() =>
  import("../views/pages/ppob/charge/charge")
);
const ppobPulse = React.lazy(() => import("../views/pages/ppob/data/pulsa"));
const ppobPln = React.lazy(() => import("../views/pages/ppob/data/pln"));
const ppobBpjs = React.lazy(() => import("../views/pages/ppob/data/bpjs"));
const ppobPdam = React.lazy(() => import("../views/pages/ppob/data/pdam"));
const ppobPbb = React.lazy(() => import("../views/pages/ppob/data/pbb"));
const ppobInternetTv = React.lazy(() =>
  import("../views/pages/ppob/data/internetTv")
);

const transactionPpob = React.lazy(() =>
  import("../views/pages/ppob/transaction/transactionData")
);
const transactionPulse = React.lazy(() =>
  import("../views/pages/ppob/transaction/transactionPulse")
);
const transactionBpjs = React.lazy(() =>
  import("../views/pages/ppob/transaction/transactionBpjs")
);
const transactionPln = React.lazy(() =>
  import("../views/pages/ppob/transaction/transactionPln")
);
const transactionPbb = React.lazy(() =>
  import("../views/pages/ppob/transaction/transactionPbb")
);
const transactionPdam = React.lazy(() =>
  import("../views/pages/ppob/transaction/transactionPdam")
);
const transactionInternetTv = React.lazy(() =>
  import("../views/pages/ppob/transaction/transactionInternetTv")
);
const financialDataPPOB = React.lazy(() =>
  import("../views/pages/ppob/financialData/financialData")
);
const companyBalancePPOB = React.lazy(() =>
  import("../views/pages/ppob/companyBalance/companyBalance")
);
const paymentMethodBalancePPOB = React.lazy(() =>
  import("../views/pages/ppob/companyBalance/paymentMethod")
);
const lastPaymentBalancePPOB = React.lazy(() =>
  import("../views/pages/ppob/companyBalance/lastPayment")
);
const profitBalancePPOB = React.lazy(() =>
  import("../views/pages/ppob/financialData/profit")
);
const billBalancePPOB = React.lazy(() =>
  import("../views/pages/ppob/financialData/bill")
);
const transactionTopupPPOB = React.lazy(() =>
  import("../views/pages/ppob/transaction/transactionTopup")
);
const chooseItemPpob = React.lazy(() =>
  import("../views/pages/ppob/data/chooseItemPpob")
);

const ppobRoutes = [
  {
    path: "/ppob/charge-config",
    element: chargePpob,
  },
  {
    path: "/transaction/purchase-history/ppob/pulse-data",
    element: ppobPulse,
  },
  {
    path: "/transaction/purchase-history/ppob/pln",
    element: ppobPln,
  },
  {
    path: "/transaction/purchase-history/ppob/bpjs",
    element: ppobBpjs,
  },
  {
    path: "/transaction/purchase-history/ppob/pdam",
    element: ppobPdam,
  },
  {
    path: "/transaction/purchase-history/ppob/pbb",
    element: ppobPbb,
  },
  {
    path: "/transaction/purchase-history/ppob/internetTv",
    element: ppobInternetTv,
  },

  {
    path: "/ppob/transaction-data",
    element: transactionPpob,
  },
  {
    path: "/ppob/transaction-data/pulse",
    element: transactionPulse,
  },
  {
    path: "/ppob/transaction-data/bpjs",
    element: transactionBpjs,
  },
  {
    path: "/ppob/transaction-data/pln",
    element: transactionPln,
  },
  {
    path: "/ppob/transaction-data/pbb",
    element: transactionPbb,
  },
  {
    path: "/ppob/transaction-data/pdam",
    element: transactionPdam,
  },
  {
    path: "/ppob/transaction-data/internetTv",
    element: transactionInternetTv,
  },
  {
    path: "/ppob/financial-data",
    element: financialDataPPOB,
  },
  {
    path: "/ppob/company-balance",
    element: companyBalancePPOB,
  },
  {
    path: "/ppob/company-balance/payment-method",
    element: paymentMethodBalancePPOB,
  },
  {
    path: "/ppob/company-balance/last-payment",
    element: lastPaymentBalancePPOB,
  },
  {
    path: "/ppob/financial-data/company-profit",
    element: profitBalancePPOB,
  },
  {
    path: "/ppob/financial-data/company-bill",
    element: billBalancePPOB,
  },
  {
    path: "ppob/transaction-data/topup",
    element: transactionTopupPPOB,
  },
  {
    path: "/transaction/ppob/purchase-history",
    element: chooseItemPpob,
  },
];

export default ppobRoutes;
