import React from "react";
import academicRoutes from "./config/academicRoutes";
import accessibilityManagementRoutes from "./config/accessibilityManagementRoutes";
import balanceRoutes from "./config/balanceRoutes";
import cardRoutes from "./config/cardRoutes";
import donationRoutes from "./config/donationRoutes";
import financialRoutes from "./config/financialRoutes";
import integrationManagementRoutes from "./config/integrationManagementRoutes";
import invoiceRoutes from "./config/invoiceRoutes";
import marketplaceRoutes from "./config/marketplaceRoutes";
import newsRoutes from "./config/newsRoutes";
import notificationRoutes from "./config/notificationRoutes";
import ppobRoutes from "./config/ppobRoutes";
import propertiesRoutes from "./config/propertiesRoutes";
import superadminRoutes from "./config/superadminRoutes";
import userRoutes from "./config/userRoutes";
const Login = React.lazy(() => import("./views/pages/login/Login"));
const cekCompany = React.lazy(() =>
  import("./views/pages/cekCompany/cekCompany")
);
const forgotPassword = React.lazy(() =>
  import("./views/pages/forgotPassword/forgotPassword")
);
// const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));

const routes = [
  // { path: "", name: 'cekCompany', element: cekCompany },
  // { path: "", name: "Login", element: Login },
  { path: "/", name: "Login", element: Page404 },
  { path: "/:cid", name: "Login", element: Login },
  { path: "/forget-password", name: "Lupa Password", element: forgotPassword },
  { path: "/companies", name: "cekCompany", element: cekCompany },
  // { path: "/dashboard", name: "Dashboard", element: Dashboard },

  ...propertiesRoutes,
  ...userRoutes,
  ...newsRoutes,
  ...notificationRoutes,
  ...cardRoutes,
  ...invoiceRoutes,
  ...balanceRoutes,
  ...financialRoutes,
  ...marketplaceRoutes,
  ...donationRoutes,
  ...ppobRoutes,
  ...accessibilityManagementRoutes,
  ...integrationManagementRoutes,
  ...academicRoutes,
  ...superadminRoutes,
];

export default routes;
