import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { saveState, loadState } from "../browser-storage/browser-storage";

const initialState = {
  firstDay: "",
  lastDay: "",
  jenis: "",
  select: "",
};

export const filterDataSlice = createSlice({
  name: "filterData",
  initialState: initialState,
  reducers: {
    setFirstDay: (state, action) => {
      state.firstDay = action.payload;
    },
    setLastDay: (state, action) => {
      state.lastDay = action.payload;
    },
    setJenis: (state, action) => {
      state.jenis = action.payload;
    },
    setSelect: (state, action) => {
      state.select = action.payload;
    },
    removeFilterData: (state) => {
      state.firstDay = "";
      state.lastDay = "";
      state.jenis = "";
      state.select = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setFirstDay,
  setLastDay,
  setJenis,
  setSelect,
  removeFilterData,
} = filterDataSlice.actions;

export default filterDataSlice.reducer;
