import React from "react";
const newsAndInfo = React.lazy(() => import("../views/pages/news/newsInfo"));
const detailNews = React.lazy(() => import("../views/pages/news/detailNews"));
const detailInfo = React.lazy(() => import("../views/pages/news/detailInfo"));
const newNews = React.lazy(() => import("../views/pages/news/newNews"));
const editNews = React.lazy(() => import("../views/pages/news/editNews"));
const newInfo = React.lazy(() => import("../views/pages/news/newInfo"));
const editInfo = React.lazy(() => import("../views/pages/news/editInfo"));

const newsRoutes = [
  {
    path: "/news-info",
    element: newsAndInfo,
  },
  {
    path: "/news-info/detail-news/:id",
    element: detailNews,
  },
  {
    path: "/news-info/detail-info/:id",
    element: detailInfo,
  },
  {
    path: "/news-info/new-news",
    element: newNews,
  },
  {
    path: "/news-info/edit-news/:id",
    element: editNews,
  },
  {
    path: "/news-info/new-info",
    element: newInfo,
  },
  {
    path: "/news-info/edit-info/:id",
    element: editInfo,
  },
];

export default newsRoutes;
