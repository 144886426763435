import { cilUser } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CAvatar,
  CButton,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import React, { useState } from "react";

import { faPowerOff, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ImageIcon from "src/assets/images/Iconly/Light-Outline/Image.svg";
import { removeCompany } from "src/core/feature/company/companySlice";
import { setLoading } from "src/core/feature/config/configSlice";
import { useAppSelector } from "src/core/feature/hooks";
import { removeMenu } from "src/core/feature/menu/menuSlice";
import { removeProfile } from "src/core/feature/profile/profileSlice";
import { removeToken } from "src/core/feature/token/tokenSlice";
import { removeUser } from "src/core/feature/user/userSlice";
import * as API from "src/core/services/api";
import { ValidateSuccess } from "src/core/services/interceptor_error_message";
import { removeFinancialType } from "src/core/feature/financialType/financialType";

const AppHeaderDropdown = () => {
  const company = useAppSelector((state) => state.company.company);
  const user = useAppSelector((state) => state.user.user);
  const profile = useAppSelector((state) => state.profile.profile);
  const pathPrefix = JSON.parse(localStorage.getItem("pathPrefix"));

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);

  const logout = () => {
    dispatch(setLoading(true));
    API.logout()
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(removeToken());
        dispatch(removeUser());
        dispatch(removeCompany());
        dispatch(removeMenu());
        dispatch(removeProfile());
        dispatch(removeFinancialType());
        localStorage.removeItem("create_topupVa");
        localStorage.removeItem("version");
        toast.success(ValidateSuccess(res.data));
        navigate(`/${pathPrefix}`);
        // localStorage.removeItem("pathPrefix");
      })
      .catch((e) => {
        dispatch(setLoading(false));
      });
  };

  return (
    <CDropdown variant="nav-item">
      <Modal isOpen={modalIsOpen} toggle={() => setIsOpen(false)}>
        <ModalHeader toggle={() => setIsOpen(false)}>Konfirmasi</ModalHeader>
        <ModalBody>Apakah Anda yakin ingin keluar?</ModalBody>
        <ModalFooter>
          <CButton
            color=""
            type="button"
            size="sm"
            style={{
              background: "#fff",
              color: "#E50200",
              border: "1px solid #E50200",
              borderRadius: "8px",
            }}
            onClick={() => setIsOpen(false)}
          >
            Batal
          </CButton>
          <CButton
            color=""
            type="button"
            size="sm"
            style={{
              background: "#5D5FEF",
              color: "#fff",
              borderRadius: "8px",
            }}
            onClick={() => logout()}
          >
            Keluar
          </CButton>
        </ModalFooter>
      </Modal>

      <CDropdownToggle placement="bottom-end" className="py-2" caret={false}>
        <FontAwesomeIcon icon={faSortDown} />
      </CDropdownToggle>
      <CDropdownMenu
        className="pt-0 mt-2"
        placement="bottom-end"
        style={{
          borderColor: "#fff",
          borderRadius: "0px",
          boxShadow: "0px 2px 12px 0px #0000000A",
        }}
      >
        <CDropdownItem href="/profile" style={{ color: "#A3AED0" }}>
          <svg
            className="me-2"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M9.99996 18.3417C14.6023 18.3417 18.3333 14.6107 18.3333 10.0083C18.3333 5.40596 14.6023 1.675 9.99996 1.675C5.39759 1.675 1.66663 5.40596 1.66663 10.0083C1.66663 14.6107 5.39759 18.3417 9.99996 18.3417Z"
              fill="#A3AED0"
            />
            <path
              d="M10 5.78334C8.275 5.78334 6.875 7.18334 6.875 8.90834C6.875 10.6 8.2 11.975 9.95833 12.025C9.98333 12.025 10.0167 12.025 10.0333 12.025C10.05 12.025 10.075 12.025 10.0917 12.025C10.1 12.025 10.1083 12.025 10.1083 12.025C11.7917 11.9667 13.1167 10.6 13.125 8.90834C13.125 7.18334 11.725 5.78334 10 5.78334Z"
              fill="#A3AED0"
            />
            <path
              d="M15.65 16.1333C14.1666 17.5 12.1833 18.3417 9.99998 18.3417C7.81664 18.3417 5.83331 17.5 4.34998 16.1333C4.54998 15.375 5.09164 14.6833 5.88331 14.15C8.15831 12.6333 11.8583 12.6333 14.1166 14.15C14.9166 14.6833 15.45 15.375 15.65 16.1333Z"
              fill="#A3AED0"
            />
          </svg>
          Profil
        </CDropdownItem>
        <CDropdownItem
          style={{ cursor: "pointer", color: "#A3AED0" }}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <svg
            className="me-2"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M7.5 6V13.9917C7.5 16.6667 9.16667 18.3333 11.8333 18.3333H13.9917C16.6583 18.3333 18.325 16.6667 18.325 14V6C18.3333 3.33333 16.6667 1.66666 14 1.66666H11.8333C9.16667 1.66666 7.5 3.33333 7.5 6Z"
              fill="#A3AED0"
            />
            <path
              d="M4.64162 6.76666L1.84995 9.55833C1.60828 9.8 1.60828 10.2 1.84995 10.4417L4.64162 13.2333C4.88328 13.475 5.28328 13.475 5.52495 13.2333C5.76662 12.9917 5.76662 12.5917 5.52495 12.35L3.79995 10.625H12.7083C13.05 10.625 13.3333 10.3417 13.3333 10C13.3333 9.65833 13.05 9.375 12.7083 9.375H3.79995L5.52495 7.65C5.64995 7.525 5.70828 7.36666 5.70828 7.20833C5.70828 7.05 5.64995 6.88333 5.52495 6.76666C5.28328 6.51666 4.89162 6.51666 4.64162 6.76666Z"
              fill="#A3AED0"
            />
          </svg>
          Keluar
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
