import React from "react";
const siakadManagement = React.lazy(() =>
  import("../views/pages/siakad/userManagement")
);
const propertiesMenuLms = React.lazy(() =>
  import("../views/pages/siakad/configMenuLms")
);

const academicRoutes = [
  {
    path: "/siakad-management",
    element: siakadManagement,
  },

  {
    path: "/siakad-management/properties-menu",
    element: propertiesMenuLms,
  },
];

export default academicRoutes;
