// import * as API_TRANSACTION from "../core/service/api_transaction";
// import * as API_COMMERCE from "../core/service/api_commerce";
// import * as API_DIGIPROD from "../core/service/api_digiprod";

import moment from "moment";
import ImageIcon from "../assets/images/Iconly/Light-Outline/Image.svg";
import ProfileIcon from "../assets/images/Iconly/Bold/Profile.svg";
import { apiDownloadPdf } from "src/core/services/api_downloadData";
import { filter, map } from "lodash";
var idLocale = require("moment/locale/id");
const momentLocal = moment.locale("id", idLocale);

export const createArrayEntityFromObject = (obj) => {
  return Object.keys(obj);
};

export const getEntity = (keys, obj) => {
  return keys
    .map((k) => {
      if (typeof obj[k] == "boolean") {
        // console.log("obj[k]", obj[k], "[k]", [k]);
        // return obj[k];
        return { key: [k], value: obj[k] };
      } else {
        if (obj[k]) return { key: [k], value: obj[k] };
      }
    })
    .filter((el) => el != undefined);
};

export const getEntityPy = (keys, obj) => {
  return keys
    .map((k) => {
      if (typeof obj[k] == "boolean" || typeof obj[k] == "number") {
        // console.log("obj[k]", obj[k], "[k]", [k]);
        // return obj[k];
        return { key: [k], value: obj[k] };
      } else {
        if (obj[k]) return { key: [k], value: obj[k] };
      }
    })
    .filter((el) => el != undefined);
};

export const createQueryUrl = (arr, separator) => {
  let query = "";
  arr.map((el, n) => {
    if (arr.length - 1 > n)
      return (query = `${query}${el.key}=${el.value}${separator}`);
    if (typeof el.value == "boolean" || typeof el.value == "number") {
      // console.log("el", el);
      query = `${query}${el.key}=${el.value}`;
    } else if (Array.isArray(el.value)) {
      el.value.map((val, index) => {
        query = `${query}${el.key}=${val}`;
        if (index != el.value.length - 1) query = `${query}&`;
      });
    } else if (el.value) {
      query = `${query}${el.key}=${el.value}`;
    }
  });
  // console.log(query);
  return query;
};

export const findArray = (arr, fromKey, value, returnKey) => {
  if (arr.find((el) => el[fromKey].includes(value))) {
    return arr.find((el) => el[fromKey].includes(value))[returnKey];
  } else {
    return null;
  }
};

export const formatCurrency = (value) => {
  if (value == "" || value == null || value == undefined) return "Rp 0";
  return new Intl.NumberFormat("in-IN", {
    style: "currency",
    currency: "IDR",
  }).format(value);
};

export const onlyNumber = (evt) => {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46)
    return evt.preventDefault();
  return true;
};

export const isNumeric = (number) => {
  if (+number === +number) {
    // if is a number
    return true;
  }

  return false;
};

export const backTo = (url, query = {}) => {
  window.location.replace(url);
};

export const checkType = (data) => {
  if (data) {
    if (parseInt(data) != NaN) {
      return parseInt(data);
    }
    return data;
  }
};

export const createNewArray = (array, keys, nested = {}) => {
  let returnArr = [];
  array.map((arr) => {
    let obj = {};
    keys.map((key) => {
      obj[key.to] = arr[key.from];
    });
    if (nested && arr[nested.from]) {
      obj[nested.to] = createNewArray(arr[nested.from], keys, nested);
    }
    returnArr.push(obj);
  });
  return returnArr;
};

export const downloadPdf = (transaction_type, id, query = {}) => {
  // console.log(transaction_type);
  if (transaction_type === "marketplace_transactions") {
    API_COMMERCE.downloadPDFMarketplace(id)
      .then((response) => {
        let name = "MARKETPLACE.pdf"; //response.data.data.url.split();
        let url = response.data.url;
        var link = document.createElement("a");
        // console.log(response);
        link.href = url;
        link.target = "_blank";
        link.download = name;
        link.setAttribute("download", `${name}`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  } else if (transaction_type === "digital_product_transactions") {
    API_DIGIPROD.downloadPDF(id)
      .then((response) => {
        // console.log(response);
        let name = response.data.name;
        let url = response.data.url;
        var link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.download = name;
        // console.log(name);
        link.setAttribute("download", `${name}`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  } else if (transaction_type === "pencairan_dana_admin") {
    // console.log(id);
    API_TRANSACTION.downloadPDFPencairanDana(id)
      .then((response) => {
        // console.log(response);
        let name = "PencairanDanaAdmin.pdf"; //response.data.data.url.split();
        let url = response.data.data.url;
        var link = document.createElement("a");
        // console.log(response);
        link.href = url;
        link.target = "_blank";
        link.download = name;
        link.setAttribute("download", `${name}`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  } else {
    API_TRANSACTION.downloadPDF(transaction_type, id, query)
      .then((response) => {
        // console.log(response);
        let name = response.data.data.url.split();
        var link = document.createElement("a");
        link.download = name[name.length - 1];
        link.href = response.data.data.url;
        link.click();
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  }
};
export const toMonthName = (monthNumber) => {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return moment(date).format("MMMM");
};

export const subStringText = (message, start, end, a) => {
  if (message?.length >= a) {
    return message.substring(start, end) + "...";
  } else {
    return message;
  }
};

export const camelCase = (text) => {
  return text
    .toLowerCase()
    .split(" ")
    .map((el, ind) => {
      if (el) {
        return el[0].toUpperCase() + el.substring(1, el.length);
      }
    })
    .join(" ");
};

export const replaceText = (text, from, to) => {
  return text.replaceAll(from, to);
};

export const humanizedText = (text) => {
  return text.replaceAll("-", " ").replaceAll("_", " ");
};

export const formatDatePicker = (date) => {
  let arrDate = new Date(date),
    mnth = ("0" + (arrDate.getMonth() + 1)).slice(-2),
    day = ("0" + arrDate.getDate()).slice(-2);
  return [arrDate.getFullYear(), mnth, day].join("-");
};

export const formatMonthPicker = (date) => {
  let arrDate = new Date(date);
  // mnth = (arrDate.getMonth() + 1).slice(-2);
  return arrDate.getMonth() + 1;
};
export const formatYearPicker = (date) => {
  let arrDate = new Date(date);
  return arrDate.getFullYear();
};

export const formatDatePickerUTC = (date, isEnd = false, type) => {
  let newDate = new Date(date);
  if (isEnd) {
    newDate = new Date(date).setTime(
      new Date(date).getTime() + (23 * 60 * 60 + 59 * 60 + 59) * 1000
    );
  }
  if (type == "access") {
    return moment(newDate).utc().format();
  } else {
    return moment(newDate).utc().format("YYYY-MM-DDTHH:mm:ss");
  }
};

export const formatTimePicker = (date) => {
  let d = new Date(date);
  let datetext = d.toTimeString();
  return (datetext = datetext.split(" ")[0]);
};

export const formatDatePickertoIsoString = (date) => {
  let arrDate = new Date(date);
  return arrDate.toISOString();
};

export const formatTimetoDate = (date) => {
  // let arrDate = new Date(date);
  let tempTime = date.split(":");
  let dt = new Date();
  dt.setHours(tempTime[0]);
  dt.setMinutes(tempTime[1]);
  dt.setSeconds(tempTime[2]);
  return dt;
};

export const formatDateTime = (date) => {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
};

export const formatDateTimeAdd5 = (date) => {
  return moment(date).add(5, "minutes").format("YYYY-MM-DD HH:mm:ss");
};

export const formatDate = (date, format = "ymd") => {
  switch (format) {
    case "ymd":
      return moment(date).format("YYYY-MM-DD");

      break;
    case "dmy":
      return moment(date).format("DD-MM-YYYY");

      break;
    case "hms":
      return moment(date).format("HH:mm:ss");

      break;
    case "fromNow":
      return moment(date).fromNow();

      break;

    case "my":
      return moment(date)?.format("MMMM-YYYY");

      break;

    default:
      return moment(date).format("YYYY-MM-DD");
      break;
  }
};
export const formatDateCustom = (date, format = "") => {
  return moment(date).format(format);
};
export const formatDateTimeUTC = (date) => {
  if (date) {
    return moment.utc(date).local().format("YYYY-MM-DD HH:mm:ss");
  } else {
    return "-";
  }
};
export const formatMonthUTC = (date) => {
  return moment.utc(date).local("id").format("MMMM YYYY");
};

export const formatTime = (time) => {
  let a = time.split(".")[0];
  let b = new Date();
  b.setMinutes(a.split(":")[1]);
  b.setSeconds(a.split(":")[2]);
  b.setHours(parseInt(a.split(":")[0]) + 6);
  // console.log(6 + a.split(":")[0]);
  return formatDate(b, "hms");
};

export const check = () => {
  if (localStorage.getItem("token_sn_admin_redux"))
    return JSON.parse(localStorage.getItem("token_sn_admin_redux")).replaceAll(
      '"',
      ""
    );
  return "";
};

export const checkRegex = (type, value) => {
  var strongRegex = "";
  switch (type) {
    case "email":
      strongRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
      break;
    case "password":
      strongRegex =
        /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[-\~\!\@\#\$\%\^\&\*\(\)\_\+\=\{{\}}\[\]\|\\:\;\\\"\'\<\>\,\.\/\?\ ])(?=.*[a-zA-Z]).{6,30}$/;
      break;
    case "passwordMed":
      strongRegex = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[a-zA-Z]).{4,30}$/;
      break;
    case "passwordLow":
      strongRegex = /^(?=.*[a-zA-Z\d])(?=.*[a-zA-Z\d]).{1,30}$/;
      break;
    case "noId":
      strongRegex = /^[a-zA-Z0-9\-_.]+$/;
      break;
    case "decimal":
      strongRegex = /^\d*\.?\d*$/;
      break;
    case "URL":
      strongRegex =
        /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    default:
      break;
  }
  if (strongRegex.test(value)) {
    return true;
  }
  return false;
};

export const responseArray = (value) => {
  return value?.join(", ");
};

export const detailCardTransaction = (value) => {
  switch (value) {
    case "update_inquiry":
      return value;
    case "access_parking_card_payment":
      return "Pembayaran Kartu Parkir";
    default:
      return value;
  }
};

export const imageOnError = (event, type = "") => {
  event.currentTarget.src = ImageIcon;
  if (type === "user") event.currentTarget.src = ProfileIcon;
  event.currentTarget.className = `${event.currentTarget.className} error`;
};

export const handleImageError = (event, type = "") => {
  event.currentTarget.src = ImageIcon;
  // if (type === "user") event.currentTarget.src = ProfileIcon;
  event.currentTarget.className = `${event.currentTarget.className} error`;
};

export const handleDeletedObjectArray = (obj, returnKey) => {
  _.map(returnKey, (el) => {
    if (!obj[el]) obj[el] = "-";
  });
  return obj;
};

export const getComboData = (value, combo, key) => {
  let isFind = _.find(combo, { value });
  // console.log(isFind);
  return isFind ? isFind[key] : "-";
};

export const elTablePaging = (item, paging) => {
  return (
    (item > paging.page - 2 && item <= paging.page + 3) ||
    (paging.page < 2 && item <= paging.page + 5 - paging.page) ||
    (paging.page < 2 && item <= paging.page + 5 - paging.page) ||
    (paging.page >= paging.totalPages - 2 && item > paging.totalPages - 5)
  );
};

export const statusWithdrawMerhcant = (value) => {
  switch (value) {
    case "REQUESTED":
      return "DIMINTA";
    case "PROCESSED":
      return "DIPROSES";
    case "REJECTED":
      return "DITOLAK";
    case "DONE":
      return "SELESAI";
    default:
      return value;
  }
};

export const detailMutationBalancePPOB = (value) => {
  switch (value) {
    case "PULSE_AND_DATA":
      return "PULSA DAN DATA";
    case "PLN":
      return "PLN";
    case "BPJS":
      return "BPJS";
    case "PBB":
      return "PBB";
    case "PDAM":
      return "PDAM";
    case "INTERNET_AND_TV":
      return "INTERNET DAN TV";
    case "TOP_UP_BALANCE":
      return "TOP UP SALDO";
    default:
      return value;
  }
};

export const handleImageDonation = (postImage) => {
  let filteredPostImage = filter(postImage, "url");
  return filteredPostImage;
};

export const handleImageDonationSave = (
  postImage,
  responseImage,
  list,
  format = false
) => {
  let n = 0;
  map(postImage, (item) => {
    list[item.pos]["url"] = responseImage[n]["url"];
    n++;
  });
  if (format)
    return filter(
      map(list, (item) => item.url),
      (item) => item
    );
  return list;
};

export const transactionCardDetail = (value) => {
  switch (value) {
    case "TOP_UP_CARD_BALANCE_MEMBER":
      return "Top Up Saldo Kartu";
    case "WITHDRAW_CARD_BALANCE_MEMBER":
      return "Penarikan Saldo Kartu";
    case "TOP_UP_CARD_BALANCE_MERCHANT":
      return "Top Up Saldo Kartu Via Merchant";
    case "WITHDRAW_CARD_BALANCE_MERCHANT":
      return "Penarikan Saldo Kartu Via Merchant";
    case "CREATE_CARD":
      return "Pembuatan Kartu";
    case "TRANSACTION_CARD":
      return "Transaksi Kartu";
    default:
      return value;
  }
};

export const getFirstDay = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const getLastDay = (date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

export const metodeBayarFnb = (value) => {
  switch (value) {
    case "BALANCE":
      return "CASH";
    case "CARD":
      return "KARTU";
    case "CHECKOUT":
      return "CHECKOUT";
    default:
      return value;
  }
};

export const statusParkir = (value) => {
  switch (value) {
    case "OUT_OVERTIME":
      return "Keluar Overtime";
    case "OUT_EARLY":
      return "Keluar Awal";
    case "OUT_ON_TIME":
      return "Keluar Tepat Waktu";
    case "COME_LATE":
      return "Datang Terlambat";
    case "COME_EARLY":
      return "Datang Awal";
    case "COME_ON_TIME":
      return "Datang Tepat Waktu";
    default:
      return value;
  }
};

export const convertTimeStr = (value) => {
  if (value >= 0) {
    return (
      Math.floor(value / 24 / 60) +
      " hari, " +
      Math.floor((value / 60) % 24) +
      " jam, " +
      Math.floor(value % 60) +
      " menit"
    );
  } else return "";
};
