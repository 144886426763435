import {
  CButton,
  CImage,
  CSidebar,
  CSidebarBrand,
  CSidebarHeader,
  CSidebarNav,
} from "@coreui/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import icon from "../assets/images/logo/logo-text.svg";
import { AppSidebarNav } from "./AppSidebarNav";

// sidebar nav config
import { useAppSelector } from "src/core/feature/hooks";
import { setSidebarShow } from "src/core/feature/template/templateSlice";
import navigation from "../_nav";
import navigationSuper from "../_navSuper";
import navigationLite from "../_navLite";
import navigationSubCompany from "../_navSubCompany";

const AppSidebar = () => {
  const dispatch = useDispatch();
  const unfoldable = useAppSelector(
    (state) => state.template.sidebarUnfoldable
  );
  const sidebarShow = useAppSelector((state) => state.template.sidebarShow);
  const userData = useAppSelector((state) => state.user.user);
  useEffect(() => {
    setTimeout(() => {
      let ids = document.getElementById(window.location.pathname);
      ids?.scrollIntoView({ behavior: "auto", block: "center" });
    }, 200);
  });

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => dispatch(setSidebarShow(visible))}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <CImage
          src={icon}
          style={{
            objectFit: "contain",
            width: 200,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          {userData?.userType == "SUPERADMIN" && !userData.companyId ? (
            <AppSidebarNav items={navigationSuper} isSuper={true} />
          ) : userData?.companyId &&
            JSON.parse(localStorage.getItem("version")) == "pro" ? (
            <AppSidebarNav items={navigation} isSuper={false} />
          ) : userData?.companyId &&
            JSON.parse(localStorage.getItem("version")) == "lite" ? (
            <AppSidebarNav items={navigationLite} isSuper={false} />
          ) : (
            <AppSidebarNav items={navigationSubCompany} isSuper={false} />
          )}
        </SimpleBar>
      </CSidebarNav>
      {userData?.companyId &&
      JSON.parse(localStorage.getItem("version")) == "lite" ? (
        <CSidebarHeader>
          <div>
            <div
              className="card"
              style={{
                borderRadius: "15px",
                borderColor: "#E0E2E7",
                background: "#5D5FEF",
              }}
            >
              <div className="mt-3">
                <svg
                  width="35"
                  height="35"
                  viewBox="0 0 35 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="35" height="35" rx="12" fill="white" />
                  <path
                    d="M17.5 8.49994C12.5312 8.49994 8.5 12.5312 8.5 17.4999C8.5 22.4687 12.5312 26.4999 17.5 26.4999C22.4687 26.4999 26.5 22.4687 26.5 17.4999C26.5 12.5312 22.4687 8.49994 17.5 8.49994ZM17.2187 22.7499C17.0333 22.7499 16.8521 22.695 16.6979 22.5919C16.5437 22.4889 16.4236 22.3425 16.3526 22.1712C16.2817 21.9999 16.2631 21.8114 16.2993 21.6295C16.3354 21.4477 16.4247 21.2806 16.5558 21.1495C16.6869 21.0184 16.854 20.9291 17.0359 20.893C17.2177 20.8568 17.4062 20.8753 17.5775 20.9463C17.7488 21.0173 17.8952 21.1374 17.9983 21.2916C18.1013 21.4458 18.1562 21.627 18.1562 21.8124C18.1562 22.0611 18.0575 22.2995 17.8817 22.4753C17.7058 22.6512 17.4674 22.7499 17.2187 22.7499ZM18.7862 17.9687C18.0264 18.4787 17.9219 18.946 17.9219 19.3749C17.9219 19.549 17.8527 19.7159 17.7297 19.839C17.6066 19.962 17.4397 20.0312 17.2656 20.0312C17.0916 20.0312 16.9247 19.962 16.8016 19.839C16.6785 19.7159 16.6094 19.549 16.6094 19.3749C16.6094 18.3479 17.0819 17.5313 18.0541 16.8784C18.9578 16.2718 19.4687 15.8874 19.4687 15.0423C19.4687 14.4676 19.1406 14.0312 18.4614 13.7082C18.3016 13.6323 17.9458 13.5582 17.508 13.5634C16.9586 13.5704 16.532 13.7017 16.2034 13.966C15.5837 14.4648 15.5312 15.0076 15.5312 15.0156C15.5271 15.1017 15.506 15.1863 15.4692 15.2643C15.4324 15.3423 15.3805 15.4123 15.3167 15.4703C15.2528 15.5283 15.1781 15.5732 15.0969 15.6023C15.0157 15.6315 14.9295 15.6443 14.8434 15.6402C14.7572 15.636 14.6727 15.6149 14.5946 15.5781C14.5166 15.5413 14.4466 15.4895 14.3886 15.4256C14.3306 15.3617 14.2857 15.287 14.2566 15.2058C14.2275 15.1246 14.2146 15.0385 14.2187 14.9523C14.2239 14.8384 14.3031 13.8123 15.3798 12.946C15.9381 12.497 16.6483 12.2635 17.4892 12.2532C18.0845 12.2462 18.6437 12.347 19.023 12.526C20.1578 13.0628 20.7812 13.9576 20.7812 15.0423C20.7812 16.6281 19.7214 17.3401 18.7862 17.9687Z"
                    fill="#5D5FEF"
                  />
                </svg>
                <div style={{ color: "#fff", margin: "1rem" }}>
                  <p>
                    <b style={{ fontSize: ".975rem" }}>Data Lebih Lengkap?</b>
                  </p>

                  <p>pindah ke Advanced Version untuk data yang lebih detail</p>
                  <CButton
                    size="sm"
                    color=""
                    style={{
                      background: "#fff",
                      color: "#5D5FEF",
                      borderRadius: "8px",
                      fontWeight: "800",
                    }}
                    onClick={() => {
                      localStorage.setItem("version", JSON.stringify("pro"));
                      window.location.replace("/main/company-info");
                    }}
                  >
                    Advanced Version
                  </CButton>
                </div>
              </div>
            </div>
          </div>
        </CSidebarHeader>
      ) : null}
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
