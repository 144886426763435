import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import Company from '../../models/company'
import {
  saveState,
  loadState,
  removeState,
} from "../browser-storage/browser-storage";

const type = "info";

const initialState = {
  info: loadState(type),
};

export const infoSlice = createSlice({
  name: type,
  initialState: initialState,
  reducers: {
    setInfo: (state, action) => {
      state.info = action.payload;
      saveState(type, action.payload);
    },
    removeMenu: (state) => {
      state.menu = [];
      removeState(type);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setInfo, removeInfo } = infoSlice.actions;

export default infoSlice.reducer;