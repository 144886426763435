import { CNavGroup, CNavItem } from "@coreui/react";
import Icons from "./config/icon_menu";

const _nav = [
  {
    component: CNavItem,
    name: "Monitor Admin Dashboard",
    to: "/dashboard-monitor",
    icon: Icons.sideBarSuper.monitor,
    permission: "all",
  },
  {
    component: CNavItem,
    name: "Master Perusahaan",
    to: "/master-company",
    icon: Icons.sideBarSuper.company,
    permission: "all",
  },
  {
    component: CNavItem,
    name: "Master Saldo",
    to: "/master-balance",
    icon: Icons.sideBarSuper.balance,
    permission: "all",
  },
  {
    component: CNavItem,
    name: "Master Bank",
    to: "/master-bank",
    icon: Icons.sideBarSuper.bank,
    permission: "all",
  },
  {
    component: CNavItem,
    name: "Master Berita & Info",
    to: "/master-news",
    icon: Icons.sideBarSuper.news,
    permission: "all",
  },
  {
    component: CNavItem,
    name: "Master API Gateway",
    to: "/master-gateway",
    icon: Icons.sideBarSuper.apiGateway,
    permission: "all",
  },
  {
    component: CNavItem,
    name: "Manajemen Perangkat",
    to: "/master-device",
    icon: Icons.sideBarSuper.apiGateway,
    permission: "all",
  },
  {
    component: CNavItem,
    name: "Manajemen Keuangan",
    to: "/master-financial-management",
    icon: Icons.sideBarSuper.money,
    permission: "all",
    // items: [
    //   {
    //     component: CNavItem,
    //     name: "Keuangan Saldo Pembayaran",
    //     permission: "all",
    //     to: "/master-finance-management/balance",
    //   },
    //   {
    //     component: CNavItem,
    //     name: "Keuangan Marketplace",
    //     permission: "all",
    //     to: "/master-finance-management/marketplace",
    //   },
    //   {
    //     component: CNavItem,
    //     name: "Keuangan Kartu Virtual",
    //     permission: "all",
    //     to: "/master-finance-management/card",
    //   },
    // ],
  },
  {
    component: CNavItem,
    name: "Manajemen LMS",
    to: "/master-lms",
    icon: Icons.sideBarSuper.lms,
    permission: "all",
  },
  {
    component: CNavGroup,
    name: "Data Log",
    icon: Icons.sideBarSuper.logDataTrx,
    permission: ["all"],
    items: [
      {
        component: CNavItem,
        name: "Data Log Top Up VA",
        permission: "all",
        to: "/log-topup-va",
      },
      {
        component: CNavItem,
        name: "Data Log Transfer Bank",
        permission: "all",
        to: "/log-bank",
      },
      {
        component: CNavItem,
        name: "Data Log PPOB",
        permission: "all",
        to: "/log-ppob",
      },
    ],
  },
];

export default _nav;
