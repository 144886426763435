import React from "react";
const companyInfo = React.lazy(() =>
  import("../views/pages/main/companyInfo/info")
);
const selfRegister = React.lazy(() =>
  import("../views/pages/main/selfRegister/selfRegister")
);
const menuMerchant = React.lazy(() =>
  import("../views/pages/main/menu/merchant")
);
const menuMember = React.lazy(() => import("../views/pages/main/menu/member"));
const companyProperties = React.lazy(() =>
  import("../views/pages/main/properties/properties")
);
const adminProfile = React.lazy(() => import("../views/pages/profile/profile"));
const propertiesMainMenu = React.lazy(() =>
  import("../views/pages/main/choosePropertiesCompany")
);
const chooseSubMenu = React.lazy(() =>
  import("../views/pages/main/chooseSubMenu")
);

const propertiesRoutes = [
  {
    path: "/main/company-info",
    element: companyInfo,
  },
  {
    path: "/main/self-register",
    element: selfRegister,
  },
  {
    path: "/main/menu-merchant",
    element: menuMerchant,
  },
  {
    path: "/main/menu-member",
    element: menuMember,
  },
  {
    path: "/main/company-properties",
    element: companyProperties,
  },
  {
    path: "/profile",
    element: adminProfile,
  },

  {
    path: "/properties-main-menu",
    element: propertiesMainMenu,
  },
  {
    path: "/submenu-configuration",
    element: chooseSubMenu,
  },
];

export default propertiesRoutes;
