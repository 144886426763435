import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { saveState, loadState } from "../browser-storage/browser-storage";

const initialState = {
  loading: false,
  showOtp: false,
  showVersionMenu: false,
};

export const configSlice = createSlice({
  name: "config",
  initialState: initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setShowOtp: (state, action) => {
      state.showOtp = action.payload;
    },
    setShowVersionMenu: (state, action) => {
      state.showVersionMenu = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setShowOtp, setShowVersionMenu } =
  configSlice.actions;

export default configSlice.reducer;
