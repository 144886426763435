import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";
import Icons from "src/config/icon_menu";

const _navLite = [
  // {
  //   component: CNavItem,
  //   name: "Dashboard",
  //   to: "/dashboard",
  //   icon: Icons.sideBar.dashboard,
  //   permission: "all",
  // },

  {
    component: CNavTitle,
    name: "LITE VERSION",
    permission: "all",
  },

  {
    component: CNavItem,
    name: "Info Perusahaan",
    to: "/main/company-info",
    icon: Icons.sideBar.infoPerusahaan,
    permission: "all",
  },

  {
    component: CNavGroup,
    name: "data_user",
    icon: Icons.sideBar.dataUser,
    permission: ["data_user"],
    items: [
      {
        component: CNavItem,
        name: "Data Admin",
        permission: "data_user",
        to: "/admin",
      },
      {
        component: CNavItem,
        name: "Data Member",
        permission: "data_user",
        to: "/user/member",
      },
      {
        component: CNavItem,
        name: "Data Merchant",
        permission: "data_user",
        to: "/user/merchant",
      },
      {
        component: CNavItem,
        name: "Saldo Member",
        permission: "saldo_dan_pembayaran",
        to: "/balance-payment/balance/member-balance",
      },
      {
        component: CNavItem,
        name: "Saldo Merchant",
        permission: "saldo_dan_pembayaran",
        to: "/balance-payment/balance/merchant-balance",
      },
    ],
  },

  {
    component: CNavGroup,
    name: "invoice",
    icon: Icons.sideBar.tagihan,
    permission: ["invoice"],
    items: [
      {
        component: CNavItem,
        name: "Master Invoice",
        to: "/invoice/master-invoice",
        permission: "invoice",
      },
      {
        component: CNavItem,
        name: "Invoice Tunggal",
        to: "/invoice/invoice-tunggal",
        permission: "invoice",
      },
      {
        component: CNavGroup,
        name: "Data Invoice",
        permission: ["invoice"],
        items: [
          {
            component: CNavItem,
            name: "Invoice Admin",
            permission: "invoice",
            to: "/invoice/invoice-admin",
          },
          {
            component: CNavItem,
            name: "Invoice Member",
            permission: "invoice",
            to: "/invoice/invoice-member",
          },
          {
            component: CNavItem,
            name: "Invoice Merchant",
            permission: "invoice",
            to: "/invoice/invoice-merchant",
          },
        ],
      },
      {
        component: CNavItem,
        name: "Data Transaksi Invoice",
        to: "/invoice/data-transaksi-invoice",
        permission: "invoice",
      },
    ],
  },

  {
    component: CNavItem,
    name: "info_dan_berita",
    to: "/news-info",
    icon: Icons.sideBar.infoBerita,
    permission: "info_dan_berita",
  },

  {
    component: CNavGroup,
    name: "Data Mutasi Saldo",
    icon: Icons.sideBar.saldoPembayaran,
    permission: ["saldo_dan_pembayaran"],
    items: [
      {
        component: CNavItem,
        name: "Mutasi Saldo Member",
        to: "/balance-payment/mutation/member-mutation",
        permission: "saldo_dan_pembayaran",
      },
      {
        component: CNavItem,
        name: "Mutasi Saldo Merchant",
        to: "/balance-payment/mutation/merchant-mutation",
        permission: "saldo_dan_pembayaran",
      },
      {
        component: CNavItem,
        name: "Data Log VA",
        to: "/log-va",
        permission: "saldo_dan_pembayaran",
      },
    ],
  },

  {
    component: CNavItem,
    name: "Pengelolaan Keuangan",
    to: "/financial-management",
    icon: Icons.sideBar.pengelolaanKeuangan,
    permission: "all",
  },
];

export default _navLite;
