import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { saveState, loadState } from "../browser-storage/browser-storage";

const initialState = {
  email: "",
  subject: "",
  username: "",
  password: "",
  transactionType: "",
  deviceId: "",
  url: "",
  method: "",
  data: {},
  navigateTo: "",
  navigateState: {},
  otpTimeout: 300000,
};

export const otpSlice = createSlice({
  name: "otp",
  initialState: initialState,
  reducers: {
    setOtpUrl: (state, action) => {
      state.url = action.payload;
    },
    setOtpMethod: (state, action) => {
      state.method = action.payload;
    },
    setOtpData: (state, action) => {
      state.data = action.payload;
    },
    setOtpNavigateState: (state, action) => {
      state.navigateState = action.payload;
    },
    setOtpNavigateTo: (state, action) => {
      state.navigateTo = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setSubject: (state, action) => {
      state.subject = action.payload;
    },
    setUsernameOtp: (state, action) => {
      state.username = action.payload;
    },
    setPasswordOtp: (state, action) => {
      state.password = action.payload;
    },
    setTransactionTypeOtp: (state, action) => {
      state.transactionType = action.payload;
    },
    setDeviceIdOtp: (state, action) => {
      state.deviceId = action.payload;
    },
    setTimeoutOtp: (state, action) => {
      state.otpTimeout = action.payload;
    },
    removeOtp: (state) => {
      state.email = "";
      state.subject = "";
      state.username = "";
      state.password = "";
      state.transactionType = "";
      state.deviceId = "";
      state.url = "";
      state.method = "";
      state.data = {};
      state.navigateState = {};
      state.navigateTo = "";
      state.otpTimeout = 300000;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setEmail,
  setSubject,
  setUsernameOtp,
  setTransactionTypeOtp,
  setDeviceIdOtp,
  setPasswordOtp,
  removeOtp,
  setOtpUrl,
  setOtpData,
  setOtpNavigateState,
  setOtpNavigateTo,
  setOtpMethod,
  setTimeoutOtp,
} = otpSlice.actions;

export default otpSlice.reducer;
