import { CButton, CCol, CRow } from "@coreui/react";
import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import OtpInput from "react-otp-input";
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  setLoading,
  setShowOtp,
  setShowVersionMenu,
} from "src/core/feature/config/configSlice";
import { useAppSelector } from "src/core/feature/hooks";
import Countdown, { zeroPad } from "react-countdown";
import { requestOtpLogin } from "src/core/services/api";
import Swal from "sweetalert2";
import * as API from "src/core/services/api";
import * as API_USER from "src/core/services/api_user";
import { setUser } from "src/core/feature/user/userSlice";
import { setProfile } from "src/core/feature/profile/profileSlice";
import { setToken } from "src/core/feature/token/tokenSlice";
import { setCompany } from "src/core/feature/company/companySlice";
import { setMenu } from "src/core/feature/menu/menuSlice";
import { toast } from "react-toastify";
import { removeOtp } from "src/core/feature/otp/otpSlice";
import { useNavigate } from "react-router-dom";
import { ValidateSuccess } from "src/core/services/interceptor_error_message";

const { useDispatch } = require("react-redux");

export const OtpComponent = (props) => {
  let isLoading = useAppSelector((state) => state.config.loading);
  let showOtp = useAppSelector((state) => state.config.showOtp);
  let subject = useAppSelector((state) => state.otp.subject);
  let email = useAppSelector((state) => state.otp.email);
  let dataOtp = useAppSelector((state) => state.otp.data);
  let urlOtp = useAppSelector((state) => state.otp.url);
  let methodOtp = useAppSelector((state) => state.otp.method);
  let otpNavigateTo = useAppSelector((state) => state.otp.navigateTo);
  let otpNavigateState = useAppSelector((state) => state.otp.navigateState);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [securityCode, setSecurityCode] = useState("");
  const [timer, setTimer] = useState(0);
  const [data, setData] = useState({});
  const [path, setPath] = useState(window.location.pathname.replace("/", ""));

  const renderer = (data) => {
    if (data.completed) {
      return (
        <div className="flex flex-col align-items-center mt-4">
          <span
            style={{
              cursor: "pointer",
              userSelect: "none",
            }}
            onClick={() => requestOtp()}
          >
            Kirim Ulang
          </span>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col align-items-center mt-4">
          <span
            className="text-center"
            style={{
              fontSize: "14px",
              margin: ".5rem",
              display: "flex",
              gap: ".25rem",
            }}
          >
            <span>Kirim ulang OTP dalam</span>
            <span className="text-[#FF9723] font-semibold ml-2">
              {zeroPad(data.minutes)}:{zeroPad(data.seconds)}
            </span>
          </span>
        </div>
      );
    }
  };

  const requestOtp = () => {
    let url, data;
    if (["CREATE_ADMIN", "UPDATE_ADMIN"].includes(props.transactionType)) {
      url = "user/admin/admin/send_otp_pic";
      data = {
        otpDestinationType: "EMAIL",
        otpType: props.transactionType,
      };
      API.otpRequestCustom(url, data)
        .then((res) => {
          setTimer(Date.now() + res.data.data.expiredMillisecond);
        })
        .catch((err) => {
          if (err.response.data?.detail?.type == "COMPANY_PIC_NOT_FOUND")
            dispatch(setShowOtp(false));
        });
    }
    if (["NewDevice"].includes(props.transactionType)) {
      let data = {
        username: props.username,
        type: "EMAIL",
      };
      requestOtpLogin(data)
        .then((res) => {
          setTimer(Date.now() + res.data.data.expiredMillisecond);
        })
        .catch((err) => {});
    }
  };

  const reauthenticate = async () => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: "Lanjutkan proses",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Konfirmasi",
      cancelButtonText: "Batal",
    }).then((res) => {
      if (res.isConfirmed) {
        dispatch(setLoading(true));
        if (props.transactionType === "NewDevice") {
          let query = dataOtp;

          API.login(query, securityCode)
            .then((res) => {
              dispatch(setLoading(false));
              dispatch(setToken(res.data.data.authToken));
              getUser(res.data.data.firstLogin);
              localStorage.setItem("pathPrefix", JSON.stringify(path));
              dispatch(removeOtp());
              dispatch(setShowOtp(false));
            })
            .catch((err) => {
              dispatch(setLoading(false));
              setSecurityCode("");
              dispatch(setShowOtp(false));
            });
        }
        if (["CREATE_ADMIN", "UPDATE_ADMIN"].includes(props.transactionType)) {
          let query = {
            ...dataOtp,
            otp: securityCode,
            otpDestinationType: "EMAIL",
          };
          let navigateTo = otpNavigateTo;
          API.otpConfirmCustom(urlOtp, query, methodOtp)
            .then((response) => {
              dispatch(setLoading(false));
              toast.success(ValidateSuccess(response.data));
              dispatch(setShowOtp(false));
              dispatch(removeOtp());
              navigate(navigateTo);
            })
            .catch((error) => {
              dispatch(setLoading(false));
            });
        }
      }
    });
  };

  const getUser = async (firstLogin) => {
    if (firstLogin == false) {
      dispatch(setLoading(true));
      API.cekToken()
        .then((response) => {
          dispatch(setLoading(false));
          dispatch(setUser(response.data.data));
          dispatch(setToken(response.data.data.authToken));
          dispatch(setCompany(response.data.data.companies));
          if (response.data.data.userType == "ADMIN") {
            // let val = [];
            API_USER.getConfigMenu()
              .then((res) => {
                // res.data.data.map((arr) => {
                //   val.push(arr.name);
                // });
                dispatch(setMenu(res.data.data));
              })
              .catch();
            API_USER.getAdminProfile()
              .then((resp) => {
                dispatch(setProfile(resp.data.data.profileImage));
              })
              .catch();
            API_USER.getCompanyFinancial()
              .then((val) => {
                dispatch(setFinancialType(val.data.data.financialType));
              })
              .catch((e) => {});
          }
          if (response.data.data.userType == "SUPERADMIN") {
            API_USER.getSuperAdminProfile()
              .then((rest) => {
                dispatch(setProfile(rest.data.data.profileImage));
              })
              .catch();
          }
          if (response.data.data.userType == "SUPERADMIN") {
            navigate("/dashboard-monitor");
          } else if (
            response.data.data.userType == "ADMIN" &&
            response.data.data.subCompanyId
          ) {
            navigate("/main/company-info");
          } else {
            dispatch(setShowVersionMenu(true));
          }
          toast.success("Berhasil Login");
        })
        .catch((e) => {
          dispatch(setLoading(false));
        });
    }
  };

  useEffect(() => {
    if (showOtp) {
      setTimer(Date.now() + props.otpTimeout);
      if (["CREATE_ADMIN", "UPDATE_ADMIN"].includes(props.transactionType)) {
        requestOtp();
      }
    }
  }, [showOtp]);

  return (
    <>
      <Modal
        centered
        isOpen={showOtp}
        toggle={() => dispatch(setShowOtp(false))}
        backdrop="static"
      >
        <LoadingOverlay active={isLoading}>
          <ModalHeader toggle={() => dispatch(setShowOtp(false))}>
            Verifikasi OTP
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col className="flex flex-col align-items-center mb-4">
                Masukan kode OTP yang telah dikirimkan ke email&nbsp;
                {["CREATE_ADMIN", "UPDATE_ADMIN"].includes(
                  props.transactionType
                ) && "PIC"}
                {/* Masukan kode OTP yang telah dikirimkan ke {email} dengan
                subjek&nbsp;{subject} */}
              </Col>
            </Row>
            <Row>
              <Col>
                <OtpInput
                  value={securityCode}
                  onChange={(code) => {
                    setSecurityCode(code);
                    data["otp"] = code;
                  }}
                  numInputs={6}
                  separator={<span>&nbsp;</span>}
                  isInputNum
                  isInputSecure
                  inputStyle={{
                    width: "55px",
                    height: "55px",
                    borderRadius: 12,
                    border:
                      securityCode.length < 6
                        ? "0.5px solid"
                        : "0.5px solid #26A69A",
                    color: securityCode.length < 6 ? "" : "#26A69A",
                  }}
                  containerStyle={{
                    justifyContent: "center",
                    height: "55px",
                  }}
                />
                <Countdown key={timer} date={timer} renderer={renderer} />
              </Col>
            </Row>
            <div className="flex flex-col align-items-center mt-4">
              <CButton
                type="button"
                size="sm"
                block
                style={{
                  background: "#0054E6",
                  color: "#fff",
                }}
                onClick={() => {
                  reauthenticate();
                }}
              >
                Konfirmasi
              </CButton>
            </div>
          </ModalBody>
        </LoadingOverlay>
      </Modal>
    </>
  );
};
