import { createSlice } from "@reduxjs/toolkit";
import {
  saveState,
  loadState,
  removeState,
} from "../browser-storage/browser-storage";

const type = "financialType";

const initialState = {
  financialType: loadState(type),
};

export const financialType = createSlice({
  name: type,
  initialState,
  reducers: {
    setFinancialType: (state, action) => {
      state.financialType = action.payload;
      saveState(type, action.payload);
    },
    removeFinancialType: (state) => {
      state.financialType = "";
      removeState(type);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFinancialType, removeFinancialType } = financialType.actions;

export default financialType.reducer;
