import { CButton, CCol, CRow } from "@coreui/react";
import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import OtpInput from "react-otp-input";
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  setLoading,
  setShowVersionMenu,
} from "src/core/feature/config/configSlice";
import { useAppSelector } from "src/core/feature/hooks";
import Countdown, { zeroPad } from "react-countdown";
import { requestOtpLogin } from "src/core/services/api";
import Swal from "sweetalert2";
import * as API from "src/core/services/api";
import * as API_USER from "src/core/services/api_user";
import { setUser } from "src/core/feature/user/userSlice";
import { setProfile } from "src/core/feature/profile/profileSlice";
import { setToken } from "src/core/feature/token/tokenSlice";
import { setCompany } from "src/core/feature/company/companySlice";
import { setMenu } from "src/core/feature/menu/menuSlice";
import { toast } from "react-toastify";
import { removeOtp } from "src/core/feature/otp/otpSlice";
import { useNavigate } from "react-router-dom";
import { ValidateSuccess } from "src/core/services/interceptor_error_message";

const { useDispatch } = require("react-redux");

export const ChooseMenuVersion = (props) => {
  let isLoading = useAppSelector((state) => state.config.loading);
  let showVersionMenu = useAppSelector((state) => state.config.showVersionMenu);
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return (
    <>
      <Modal
        centered
        isOpen={showVersionMenu}
        toggle={() => dispatch(setShowVersionMenu(false))}
        backdrop="static"
      >
        <LoadingOverlay active={isLoading}>
          <ModalBody>
            <div
              style={{
                textAlign: "center",
                fontWeight: "700",
                fontSize: "1.2rem",
              }}
            >
              Opsi Tampilan
            </div>
            <div className="d-flex justify-content-center mt-3">
              Silahkan pilih tampilan untuk melanjutkan aktivitas ke web admin
            </div>

            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <CButton
                color=""
                type="button"
                size="sm"
                style={{
                  color: "#5D5FEF",
                  background: "#fff",
                  borderRadius: "8px",
                  border: "1px solid #5D5FEF",
                  margin: "5px",
                }}
                onClick={() => {
                  localStorage.setItem("version", JSON.stringify("lite"));
                  dispatch(setShowVersionMenu(false));
                  if (user?.userType == "ADMIN") {
                    navigate("/main/company-info");
                  } else {
                    window.location.replace("/main/company-info");
                  }
                }}
              >
                Lite Admin
              </CButton>
              <CButton
                color=""
                type="button"
                size="sm"
                style={{
                  color: "#fff",
                  background: "#5D5FEF",
                  borderRadius: "8px",
                }}
                onClick={() => {
                  localStorage.setItem("version", JSON.stringify("pro"));
                  dispatch(setShowVersionMenu(false));
                  if (user?.userType == "ADMIN") {
                    navigate("/main/company-info");
                  } else {
                    window.location.replace("/main/company-info");
                  }
                }}
              >
                Advanced Admin
              </CButton>
            </div>
          </ModalBody>
        </LoadingOverlay>
      </Modal>
    </>
  );
};
